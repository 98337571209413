import { AvailableLanguages } from "../../types/locale";
import { SurveyEmailBody } from "../../types/survey";

export const createSurveyEmailBody = (
  selectedLanguages: AvailableLanguages[],
  customMessage: string,
  surveyEmailId: string
): SurveyEmailBody => {
  return {
    survey_email_id: surveyEmailId,
    content: selectedLanguages.map((language_code, i) => {
      return { language_code, order: i };
    }),
    custom_content: customMessage,
  };
};
