import { useMsal } from "@azure/msal-react";
import { Typography } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { LanguagePicker } from "../components/LanguagePicker/LanguagePicker";
import { Button } from "../components/ui/Button/Button";
import { Card } from "../components/ui/Card/Card";
import { LoadingScreen } from "../components/ui/LoadingScreen";
import { Logo } from "../components/ui/Logo";
import { Paper } from "../components/ui/Paper/Paper";
import { IconTypes } from "../components/ui/icons";
import { Icon } from "../components/ui/icons/Icon";
import { WarningIcon } from "../components/ui/icons/WarningIcon";
import { useAuth } from "../hooks/useAuth";
import useTranslation from "../hooks/useTranslation";
import User from "../models/user";
import {
  AuthClientProviderValue,
  AuthClientProviders,
  loginRequest,
} from "../providers/AuthProvider";
import Login from "../styles/illustrations/Login.gif";
import { ErrorResponse } from "../types/error";
import { IUser, InviteErrorCodes } from "../types/user";
import { USER, USER_INVITE_TOKEN_KEY } from "../utils/api.utils";
import { ProtectedRoutes, PublicRoutes } from "../utils/routes.utils";
import "./LoginPage.scss";

export const LoginPage = () => {
  const { t } = useTranslation("loginPage");
  const { onLogin, loadingAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [errMsg, setErrMsg] = useState<string>();

  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get(USER_INVITE_TOKEN_KEY) || undefined;

  const handleLogin = (
    provider: keyof AuthClientProviders,
    data: AuthClientProviderValue
  ) => {
    onLogin(provider, data)
      .then(() => {
        const userStorage = localStorage.getItem(USER);
        if (!userStorage) {
          navigate(PublicRoutes.LOGIN);
          return;
        }

        const userJSON: IUser = JSON.parse(userStorage);
        const user = new User(userJSON);
        // we always want to redirect the user to the signup page if company name is not set
        if (user.getCompanyName()) {
          const origin =
            location.state?.from?.pathname || ProtectedRoutes.DASHBOARD;
          navigate(origin);
        } else {
          navigate(PublicRoutes.SIGNUP);
        }
      })
      .catch((error) => {
        const errorCode = error.response
          .data as ErrorResponse<InviteErrorCodes>;
        setErrMsg(getErrorMessage(errorCode));
      });
  };

  const getErrorMessage = (error: ErrorResponse<InviteErrorCodes>) => {
    if (error?.extra?.code === InviteErrorCodes.EMAIL_MISMATCH) {
      return t("errors.emailMismatch");
    } else if (error?.extra?.code === InviteErrorCodes.INVALID) {
      return t("errors.invalidInviteToken");
    } else {
      return t("errors.loginFailed");
    }
  };

  const googleLogin = useGoogleLogin({
    /*
            User authenticates with Google using their Google account. The user receives
            a temporary code in response from Google which is provided to our API login
            endpoint. On verification the backend returns a response containing an
            access token and refresh token.
             */
    onSuccess: (codeResponse) => {
      handleLogin("google", {
        code: codeResponse.code,
        invite_token: inviteToken,
      });
    },
    onError: (errorResponse) => {
      console.error("TODO: we need to log this", errorResponse);
    },
    flow: "auth-code",
    redirect_uri: process.env.REACT_APP_GOOGLE_AUTH_CALLBACK_URL,
  });

  /*
        User authenticates with Microsoft using their Microsoft account. The user receives
        access token and id token in response from Microsoft which is provided to our API login
        endpoint. On verification the backend returns a response containing an
        access token and refresh token.
         */
  const { instance } = useMsal();
  const handleMicrosoftLogin = () => {
    instance
      .loginPopup(loginRequest)
      .then((res) => {
        handleLogin("microsoft", {
          access_token: res.accessToken,
          id_token: res.idToken,
          invite_token: inviteToken,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  if (loadingAuth) {
    return <LoadingScreen active={loadingAuth} />;
  }

  return (
    <div className="LoginPage">
      <div className="LoginPage__header">
        <Logo width={177} />
      </div>

      <div className="LoginPage__content">
        <div className="LoginPage__content__background">
          <img className="LoginPage__content__image" src={Login} alt="" />
        </div>
        <Card
          className="LoginPage__content__login"
          containerClassName="LoginPage__content__loginContainer"
        >
          <form className="LoginPage__content__login__form">
            <div
              className="LoginPage__content__heading"
              style={{ paddingBottom: "16px" }}
            >
              <Typography variant="h1">{t("signUp")}</Typography>
              <LanguagePicker onLoginPage />
            </div>
            <div
              className="LoginPage__content__login__form__google"
              style={{ marginBottom: "32px" }}
            >
              <p>{t("signUpDescription")}</p>
              <Button variant="contained" color="secondary">
                <a href="https://alda.co/book-a-demo">{t("signUpButton")}</a>
              </Button>
            </div>

            <div className="LoginPage__content__heading">
              <Typography variant="h1" sx={{ pb: 1 }}>
                {t("signIn")}
              </Typography>
            </div>
            <div className="LoginPage__content__login__form__google">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => googleLogin()}
              >
                <Icon type={IconTypes.GoogleG} />
                {t("signInGoogle")}
              </Button>
            </div>
            <div
              className="LoginPage__content__login__form__microsoft"
              style={{ marginTop: "5px" }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleMicrosoftLogin()}
              >
                <Icon type={IconTypes.Microsoft} />
                {t("signInMicrosoft")}
              </Button>
            </div>
            {errMsg && (
              <Paper
                showError
                className="LoginPage__content__login__form__error"
              >
                <WarningIcon />
                <Typography>{errMsg}</Typography>
              </Paper>
            )}
          </form>
        </Card>
      </div>
    </div>
  );
};
