import { GetSurveyRecommendationResponse } from "../../types/workplaceCulture";

export const storyRecommendationWidgetData: GetSurveyRecommendationResponse = {
  low_scores: [
    "No Disclosure - Félag",
    "Fatlað fólk",
    "Taugsegin einstaklingar",
  ],
  high_scores: ["Trans fólk", "Innflytjendur"],
  benchmark: true,
  insights: [
    "recommendations.marginalisedPeople",
    "recommendations.disabledPeople",
  ],
};

export const emptyRecommendationWidgetData: GetSurveyRecommendationResponse = {
  low_scores: [],
  high_scores: [],
  benchmark: true,
  insights: [],
};

export const mockRecommendationWidgetData: GetSurveyRecommendationResponse = {
  low_scores: [],
  high_scores: [],
  benchmark: true,
  insights: [
    "recommendations.marginalisedPeople",
    "recommendations.disabledPeople",
    "recommendations.nonBinaryPeople",
    "recommendations.lgbtqPeople",
    "recommendations.foreignPeople",
  ],
};
