import useTranslation from "../../hooks/useTranslation";
import SurveyDispatch from "../../models/surveyDispatch";
import { SurveyDispatchReminder } from "../../types/survey";
import { getReminderMinDate, sortReminders } from "../../utils/survey.utils";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";
import { Typography } from "../ui/Typography/Typography";
import { TrashIcon } from "../ui/icons/TrashIcon";
import { DateType } from "./SurveySettings";
import { SurveySettingsDatePicker } from "./SurveySettingsDatePicker";
import "./SurveySettings.scss";

interface SurveySettingsReminderProps {
  dispatch: SurveyDispatch;
  reminders: SurveyDispatchReminder[];
  launchDate: Date | undefined;
  dueDate: Date | undefined;
  setReminders: (reminders: SurveyDispatchReminder[]) => void;
  setIsChanged: (isChanged: boolean) => void;
  setIsError: (isError: boolean) => void;
  setSubmitError: (error: string) => void;
}

export const SurveySettingsReminder = ({
  dispatch,
  reminders,
  launchDate,
  dueDate,
  setReminders,
  setIsChanged,
  setIsError,
  setSubmitError,
}: SurveySettingsReminderProps) => {
  const { t } = useTranslation("surveyLaunchPage");
  const maxReminders = 10;

  const handleAddReminder = () => {
    reminders.length < maxReminders &&
      setReminders([
        ...reminders,
        { id: null, tempId: Date.now(), date: undefined },
      ]);
  };

  const handleDeleteReminder = (index: number) => {
    const newReminders = reminders.filter((_, i) => i !== index);
    setReminders(newReminders);
    setIsError(false);
    setSubmitError("");
    setIsChanged(true);
  };

  const handleChangeReminder = (index: number, date: Date | undefined) => {
    const newReminders = [...reminders];
    newReminders[index] = { ...newReminders[index], date };

    setReminders(sortReminders(newReminders));
    setIsChanged(true);
  };

  return (
    <div className="SurveySettingsReminder">
      {reminders.map((reminder, index) => {
        const isDisabled = reminder.id
          ? dispatch.isReminderDisabled(reminder.id)
          : false;
        return (
          <div
            className="SurveySettingsReminder__inputContainer"
            key={`${reminder.id ?? reminder.tempId}`}
          >
            <Typography
              tagVariant="p"
              desktop="body2"
              className="SurveySettingsReminder__label"
            >
              {index + 1}
            </Typography>
            <SurveySettingsDatePicker
              className="SurveySettingsReminder__inputContainer__datePicker"
              dataTestId={`reminderDate${index}`}
              value={reminder.date}
              setValue={(date) => handleChangeReminder(index, date)}
              type={DateType.REMINDER}
              disabled={isDisabled}
              minDate={getReminderMinDate(launchDate)}
              maxDate={dueDate}
              setIsChanged={setIsChanged}
              setIsError={setIsError}
            />
            <Button
              variant="contained"
              color="secondary"
              icon
              onClick={() => handleDeleteReminder(index)}
              sx={{ visibility: isDisabled ? "hidden" : "visible" }}
            >
              <TrashIcon size={16} />
            </Button>
          </div>
        );
      })}

      {reminders.length < maxReminders && (
        <Card
          className="SurveySettingsReminder__addReminder"
          inactive
          onClick={handleAddReminder}
          data-testid="addReminder"
        >
          + {t("edit.reminders.add")}
        </Card>
      )}
      {reminders.length >= maxReminders && (
        <div className="SurveySettingsReminder__info">
          <Typography tagVariant="p" desktop="body2">
            {t("edit.reminders.maxNumber")}
          </Typography>
        </div>
      )}
    </div>
  );
};
