import useTranslation from "../../hooks/useTranslation";
import { Typography } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./SurveyEmailList.scss";

export interface SurveyEmailListProps {
  emailList: string[];
  setEmailList: (emails: string[]) => void;
}
export const SurveyEmailList = ({
  emailList,
  setEmailList,
}: SurveyEmailListProps) => {
  const { t } = useTranslation("surveyLaunchPage");

  const handleDelete = (item: string) => {
    const updatedItem = emailList.filter((i) => i !== item);
    setEmailList([...updatedItem]);
  };

  return (
    <div className="SurveyEmailList">
      <Typography
        className="SurveyEmailList__title"
        tagVariant="h3"
        desktop="body1"
        weight="bold"
      >
        {t("shareLink.recipients")}
      </Typography>
      <div className="SurveyEmailList__list">
        {emailList.map((item) => (
          <div className="SurveyEmailList__list__item" key={item}>
            <div>{item}</div>
            <button
              type="button"
              className="button"
              onClick={() => handleDelete(item)}
            >
              <Icon type={IconTypes.Close} size={14} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
