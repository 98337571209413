import { Box, CircularProgress, SelectChangeEvent } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { SurveyService } from "../../api/SurveyService";
import { useAuth } from "../../hooks/useAuth";
import useTranslation from "../../hooks/useTranslation";
import { coalColor } from "../../styles/theme";
import { AvailableLanguages } from "../../types/locale";
import { SurveyEmailLanguage, SurveyEmailResponse } from "../../types/survey";
import { sanitizeHTML } from "../../utils/survey.utils";
import { SurveyEmailPreview } from "../SurveyEmailPreview/SurveyEmailPreview";
import { Button } from "../ui/Button/Button";
import { Input } from "../ui/Input/Input";
import { Modal } from "../ui/Modal/Modal";
import { Paper } from "../ui/Paper/Paper";
import { Select } from "../ui/Select/Select";
import { Snackbar } from "../ui/Snackbar/Snackbar";
import { Tab } from "../ui/Tabs/Tab";
import { TabPanel } from "../ui/Tabs/TabPanel";
import { Tabs } from "../ui/Tabs/Tabs";
import { Tooltip } from "../ui/Tooltip/Tooltip";
import { Typography } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import { createSurveyEmailBody } from "./SurveyIntroductionEmail.utils";
import "./SurveyIntroductionEmail.scss";

export interface SurveyIntroductionEmailProps {
  availableSurveyEmailLanguages: SurveyEmailLanguage[] | undefined;
  errorLoadingData: boolean;
  existingSurveyEmail: SurveyEmailResponse | undefined;
  loadingData: boolean;
  surveyDispatchId: string;
  refetchSurveyEmail: () => void;
}

export const SurveyIntroductionEmail = ({
  availableSurveyEmailLanguages,
  errorLoadingData,
  existingSurveyEmail,
  loadingData,
  surveyDispatchId,
  refetchSurveyEmail,
}: SurveyIntroductionEmailProps) => {
  const { t } = useTranslation("surveyLaunchPage");
  const { t: tCommon } = useTranslation();
  const [selectedLanguages, setSelectedLanguages] = useState<
    AvailableLanguages[]
  >([]);
  const [customMessage, setCustomMessage] = useState("");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const surveyService = new SurveyService();
  const { authInfo } = useAuth();

  const surveyEmailMutation = useMutation({
    mutationFn: () =>
      surveyService.updateSurveyEmail(
        surveyDispatchId,
        createSurveyEmailBody(
          selectedLanguages,
          customMessage,
          existingSurveyEmail?.survey_email_id || ""
        )
      ),
    onSuccess: () => {
      setSnackbarOpen(true);
      refetchSurveyEmail();
    },
  });

  useEffect(() => {
    if (existingSurveyEmail) {
      const languages = existingSurveyEmail.content
        .sort((a, b) => a.order - b.order)
        .map((emailContent) => emailContent.language_code);
      setSelectedLanguages(languages);
      setCustomMessage(existingSurveyEmail.custom_content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingSurveyEmail]);

  const handleLanguageChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    setSelectedLanguages(value as AvailableLanguages[]);
    setSelectedTabIndex(0);
  };

  const handleTabChange = (index: number) => {
    setSelectedTabIndex(index);
  };

  const handleCustomMessageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomMessage(event.target.value);
  };

  // create an array of string from the available survey email languages
  const languageLabels = availableSurveyEmailLanguages?.map(
    (surveyEmailLanguage) => {
      return {
        label: surveyEmailLanguage.native_name,
        value: surveyEmailLanguage.language_code,
      };
    }
  );

  if (errorLoadingData) {
    return (
      <div className="SurveyIntroductionEmail">
        <Typography desktop="h3">
          {t("introductionEmail.errors.noData")}
        </Typography>
      </div>
    );
  }

  const renderWarning = (message: string, className = "") => (
    <>
      <Box
        sx={{ display: { xs: "flex", md: "none" } }}
        className={`SurveyIntroductionEmail__warning ${className}`}
      >
        <Tooltip
          title={`${t("introductionEmail.warning.note")} ${message}`}
          placement="top"
          arrow
        >
          <div key={message}>
            <Icon type={IconTypes.Message} size={20} />
          </div>
        </Tooltip>
      </Box>
      <Box
        sx={{ display: { xs: "none", md: "flex" } }}
        className={`SurveyIntroductionEmail__warning ${className}`}
      >
        <Typography desktop="button2">
          {t("introductionEmail.warning.note")}
        </Typography>
        <Typography
          desktop="button2"
          className="SurveyIntroductionEmail__warning--primary"
        >
          {message}
        </Typography>
      </Box>
    </>
  );

  return (
    <div className="SurveyIntroductionEmail">
      <Paper className="SurveyIntroductionEmail__info">
        <Icon type={IconTypes.Message} size={16} color={coalColor} />
        {t("introductionEmail.info")}
      </Paper>
      <Select
        labelId="availableSurveyEmailLanguages"
        id="availableSurveyEmailLanguages"
        value={selectedLanguages}
        multiple
        required
        onChange={handleLanguageChange}
        label={t("introductionEmail.title")}
        items={languageLabels ? languageLabels : []}
        disabled={loadingData}
        showValueOrder
        data-testid="select-email-language"
        className="SurveyIntroductionEmail__languageSelect"
      />

      {renderWarning(
        t("introductionEmail.warning.above"),
        "SurveyIntroductionEmail__warning--input"
      )}

      <Input
        label={`${t("introductionEmail.customContent")} ${
          authInfo?.user?.company?.name
        }`}
        multiline
        minRows={3}
        fullWidth
        className="SurveyIntroductionEmail__customContent"
        value={customMessage}
        onChange={handleCustomMessageChange}
      />

      {loadingData && (
        <div className="SurveyIntroductionEmail__loading">
          <CircularProgress />
        </div>
      )}

      {!loadingData && (
        <>
          <Tabs
            selectedTabIndex={selectedTabIndex}
            className="SurveyIntroductionEmail__languageTabs"
          >
            {selectedLanguages.map((lang_code, index) => {
              const nativeLanguageName = availableSurveyEmailLanguages?.find(
                (surveyEmailLanguage) =>
                  surveyEmailLanguage.language_code === lang_code
              )?.native_name;
              return (
                <Tab
                  label={
                    nativeLanguageName ||
                    tCommon(`navigation.languages.${lang_code}`)
                  }
                  index={index}
                  onClick={handleTabChange}
                  key={lang_code}
                  data-testid={`tab-${lang_code}`}
                />
              );
            })}
          </Tabs>
          {renderWarning(t("introductionEmail.warning.notEditable"))}
          {selectedLanguages.map((lang_code, index) => {
            const surveyEmail = availableSurveyEmailLanguages?.find(
              (emailContent) => emailContent.language_code === lang_code
            );

            let sanitizedHTML = sanitizeHTML(surveyEmail?.html_body || "");

            return (
              <TabPanel
                className="SurveyIntroductionEmail__languageTabpanel"
                selectedTabIndex={selectedTabIndex}
                index={index}
                label="tab"
                key={lang_code}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: sanitizedHTML,
                  }}
                ></p>
              </TabPanel>
            );
          })}
          {selectedLanguages.length === 0 && (
            <TabPanel
              className="SurveyIntroductionEmail__languageTabpanel"
              selectedTabIndex={selectedTabIndex}
              index={0}
              label="tab"
              key={"no_lang"}
            >
              {t("introductionEmail.noLanguageSelected")}
            </TabPanel>
          )}
        </>
      )}
      <div
        className={
          loadingData || !selectedLanguages.length
            ? "SurveyIntroductionEmail__preview SurveyIntroductionEmail__preview--disabled"
            : "SurveyIntroductionEmail__preview"
        }
        onClick={
          loadingData || !selectedLanguages.length
            ? undefined
            : () => setShowPreviewModal(true)
        }
      >
        {t("introductionEmail.preview")}
      </div>
      <Button
        variant="contained"
        onClick={() => surveyEmailMutation.mutate()}
        disabled={
          selectedLanguages.length === 0 ||
          surveyEmailMutation.isLoading ||
          loadingData
        }
        loading={surveyEmailMutation.isLoading}
        className="SurveyIntroductionEmail__saveButton"
      >
        {t("introductionEmail.save")}
      </Button>
      {surveyEmailMutation.isError && (
        <Typography desktop="body2" color="error">
          {t("introductionEmail.errors.saveError")}
        </Typography>
      )}
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t("introductionEmail.saveSuccess")}
        autoHideDuration={5000}
        data-testid="snackbar-email-save-message"
      />
      <Modal
        open={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
        title={
          <Typography desktop="h3">{t("introductionEmail.preview")}</Typography>
        }
      >
        <SurveyEmailPreview
          customMessage={customMessage}
          surveyEmailLanguages={selectedLanguages?.map(
            (lang_code) =>
              availableSurveyEmailLanguages?.find(
                (surveyLang) => surveyLang.language_code === lang_code
              ) || {
                html_body: "",
                language_code: lang_code,
                name: "",
                native_name: "",
                subject: "",
              }
          )}
        />
      </Modal>
    </div>
  );
};
