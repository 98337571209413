import { useMergeLink } from "@mergeapi/react-merge-link";
import {
  Add as AddIcon,
  MoreVert as MoreVertIcon,
  Sync as SyncIcon,
} from "@mui/icons-material";
import { Checkbox, FormControlLabel, Skeleton } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { CompanyService } from "../../api/CompanyService";
import { IntegrationService } from "../../api/IntegrationService";
import useTranslation from "../../hooks/useTranslation";
import { IntegrationResponse } from "../../types/integration";
import { getFormattedDate } from "../../utils/formatters";
import { H3Setup } from "../IntegrationSetup/H3Setup";
import { KjarniSetup } from "../IntegrationSetup/KjarniSetup";
import { Modal } from "../ui/Modal/Modal";
import { Typography } from "../ui/Typography/Typography";
import { Button } from "./../ui/Button/Button";
import { Card } from "./../ui/Card/Card";
import "./Integration.scss";

export const Integration = () => {
  const { t, i18n } = useTranslation("settingsPage");
  const integrationService = new IntegrationService();
  const companyService = new CompanyService();
  const [openKjarniSetup, setOpenKjarniSetup] = useState(false);
  const [openH3Setup, setOpenH3Setup] = useState(false);
  const [noIntegration, setNoIntegration] = useState(false);
  const [clicked, setClicked] = useState({
    merge: false,
    kjarni: false,
    h3: false,
  });
  const [openTerms, setOpenTerms] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const {
    data: integrationData,
    isLoading: integrationLoading,
    error: integrationError,
    refetch: refetchIntegration,
  } = useQuery<IntegrationResponse, AxiosError>(["integration"], () =>
    integrationService.getIntegration()
  );
  const {
    data: linkTokenData,
    isLoading: linkTokenLoading,
    error: linkTokenError,
  } = useQuery<{ link_token: string }, AxiosError>(
    ["linkToken"],
    () => integrationService.getLinkToken(),
    {
      enabled: !integrationLoading && noIntegration,
    }
  );

  const { data: syncDateData, refetch: refetchSyncDate } = useQuery<
    { sync_date: string },
    AxiosError
  >(["syncDate"], () => integrationService.getSyncDate(), {
    enabled: !!integrationData,
  });

  const addIntegrationMutation = useMutation({
    mutationFn: (publicToken: string) =>
      integrationService.addMergeIntegration(publicToken),
  });

  const addIntegration = async (publicToken: string) => {
    addIntegrationMutation.mutateAsync(publicToken, {
      onSuccess: () => {
        refetchIntegration();
        refetchSyncDate();
      },
    });
  };

  const acceptAdditionalTermsMutation = useMutation({
    mutationFn: () => companyService.acceptAdditionalDataProcessingAgreement(),
  });

  const handleClick = (type: string) => {
    setOpenTerms(true);
    setClicked({ merge: false, kjarni: false, h3: false });
    switch (type) {
      case "merge":
        setClicked({ ...clicked, merge: true });
        break;
      case "kjarni":
        setClicked({ ...clicked, kjarni: true });
        break;
      case "h3":
        setClicked({ ...clicked, h3: true });
        break;
      default:
        break;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreeToTerms(event.target.checked);
  };

  const handleCloseTerms = () => {
    setOpenTerms(false);
    setClicked({ merge: false, kjarni: false, h3: false });
  };

  const handleAgree = async () => {
    acceptAdditionalTermsMutation.mutateAsync(undefined, {
      onSuccess: () => {
        setOpenTerms(false);
        if (linkTokenData?.link_token && clicked.merge) {
          open();
        } else if (clicked.kjarni) {
          setOpenKjarniSetup(true);
        } else if (clicked.h3) {
          setOpenH3Setup(true);
        }
        setClicked({ merge: false, kjarni: false, h3: false });
      },
    });
  };

  const { open, isReady } = useMergeLink({
    linkToken: linkTokenData?.link_token,
    // FIXME: use env variable for the apiBaseURL
    // The default apiBaseURL value is https://api.merge.dev.
    tenantConfig: { apiBaseURL: "https://api-eu.merge.dev" },
    onSuccess: addIntegration,
  });

  useEffect(() => {
    integrationError?.response?.status === 404
      ? setNoIntegration(true)
      : setNoIntegration(false);
  }, [integrationError]);

  return (
    <div className="Integration">
      {integrationData && (
        <Card className="Integration__card--actions Integration__card">
          <div className="Integration__card__info">
            <div className="Integration__card__info__img">
              <img
                src={integrationData.image}
                alt={`Connected to ${integrationData.name}`}
                height="20"
              />
              {!integrationData.image && (
                <Typography tagVariant="p" desktop="body2">
                  {integrationData.name === "Kjarni"
                    ? t(`integration.connectedKjarni`)
                    : t(`integration.connected`, {
                        integrationName: integrationData.name,
                      })}
                </Typography>
              )}
            </div>

            {syncDateData?.sync_date && (
              <div>
                <div className="Integration__card__info--secondary">
                  {t("integration.synced")}
                </div>
                <div>
                  {getFormattedDate(syncDateData.sync_date, t, i18n.language)}
                </div>
              </div>
            )}
          </div>

          <div className="Integration__card__actions">
            <Button
              className="Integration__card__actions__button"
              small
              color="secondary"
              variant="outlined"
              disabled
            >
              <SyncIcon sx={{ mr: 1 }} />
              {t("integration.refresh")}
            </Button>
            <Button small icon color="secondary" variant="outlined" disabled>
              <MoreVertIcon />
            </Button>
          </div>
        </Card>
      )}
      {!integrationData && (
        <Card
          className="Integration__card"
          disabled={!isReady}
          onClick={() => handleClick("merge")}
          inactive
        >
          {(integrationLoading || linkTokenLoading) && (
            <div className="Integration__card__loading">
              <Skeleton width="50%" /> <Skeleton width="10%" />
            </div>
          )}
          {!(integrationLoading || linkTokenLoading) && (
            <>
              <AddIcon sx={{ mr: 1 }} />
              <Typography tagVariant="p" desktop="body2">
                {t("connect")}
              </Typography>
            </>
          )}
        </Card>
      )}
      {noIntegration && !integrationLoading && (
        <>
          <Card
            className="Integration__card"
            inactive
            onClick={() => handleClick("kjarni")}
          >
            <AddIcon sx={{ mr: 1 }} />
            <Typography tagVariant="p" desktop="body2">
              {t("integration.setup.kjarni.connect")}
            </Typography>
          </Card>
          <Card
            className="Integration__card"
            inactive
            onClick={() => handleClick("h3")}
          >
            <AddIcon sx={{ mr: 1 }} />
            <Typography tagVariant="p" desktop="body2">
              {t("integration.setup.h3.connect")}
            </Typography>
          </Card>
        </>
      )}

      {openKjarniSetup && (
        <KjarniSetup
          title={t("integration.setup.kjarni.connect")}
          open={openKjarniSetup}
          onClose={() => setOpenKjarniSetup(false)}
          refetchIntegration={refetchIntegration}
        />
      )}
      {openH3Setup && (
        <H3Setup
          title={t("integration.setup.h3.connect")}
          open={openH3Setup}
          onClose={() => setOpenH3Setup(false)}
          refetchIntegration={refetchIntegration}
        />
      )}

      <Modal
        open={openTerms}
        onClose={handleCloseTerms}
        title={
          <Typography tagVariant="h1" desktop="h1">
            {t("integration.terms.title")}
          </Typography>
        }
      >
        <div className="Integration__terms">
          <Typography tagVariant="p" desktop="body2">
            {t("integration.terms.info")}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                value={true}
                checked={agreeToTerms}
                onChange={handleChange}
                required
              />
            }
            style={{ marginBottom: "1rem" }}
            label={
              <Typography tagVariant="p" desktop="body2">
                {t("integration.terms.agree")}
              </Typography>
            }
          />
          <div className="Integration__terms__actions">
            <Button
              variant="contained"
              onClick={handleAgree}
              disabled={!agreeToTerms}
            >
              {t("integration.terms.next")}
            </Button>
          </div>
        </div>
      </Modal>
      {integrationError && !noIntegration && (
        <Typography tagVariant="p" desktop="caption" color="error">
          {t("integrationError")}
        </Typography>
      )}

      {linkTokenError && (
        <Typography tagVariant="p" desktop="caption" color="error">
          {t("linkTokenError")}
        </Typography>
      )}
    </div>
  );
};
