import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { ChangeEvent, useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { Button } from "../ui/Button/Button";
import { Typography } from "../ui/Typography/Typography";
import "./UploadFile.scss";

const accept = "text/plain, .csv";

export interface UploadFileProps {
  label: string;
  onFileUpload: (emails: string[]) => void;
  fileUploaded?: boolean;
}

export const UploadFile = ({
  label,
  onFileUpload,
  fileUploaded = false,
}: UploadFileProps) => {
  const { t } = useTranslation("surveyLaunchPage");
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const acceptedTypes = accept.split(",").map((type) => type.trim());
      const fileExtension = file.name.split(".").pop();
      const isAcceptedType = acceptedTypes.some(
        (acceptedType) =>
          file.type === acceptedType ||
          fileExtension === acceptedType.replace(".", "")
      );

      if (isAcceptedType) {
        setSelectedFile(file);
        const emails = await readFile(file);
        if (emails.length > 0) {
          onFileUpload(emails);
        } else {
          setErrorMsg(t("shareLink.upload.errors.noEmails") || "");
        }
      } else {
        setErrorMsg(t("shareLink.upload.errors.fileType") || "");
      }
    }
  };

  selectedFile && fileUploaded && setSelectedFile(undefined);
  const readFile = (file: File): Promise<string[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileContent = reader.result as string;
        const lines = fileContent.split(/[\n,]+/);
        const emails = lines.map((line) => line.trim());
        resolve(emails);
      };
      reader.onerror = () => {
        reject(new Error("Failed to read the file"));
      };
      file && reader.readAsText(file, "UTF-8");
    });
  };

  return (
    <label className="UploadFile" htmlFor={label}>
      <input
        id={label}
        type="file"
        onChange={handleFileChange}
        accept={accept}
        hidden
      />
      <Button
        className="UploadFile__button"
        variant="contained"
        color="secondary"
        component="span"
        small
      >
        <FileUploadOutlinedIcon />
        {label}
      </Button>
      {errorMsg && (
        <div className="UploadFile__error">
          <Typography desktop="caption" color="error">
            &nbsp;{errorMsg}
          </Typography>
        </div>
      )}
    </label>
  );
};
