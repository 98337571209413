import useTranslation from "../../hooks/useTranslation";
import { BenchmarkingIllustration } from "../../styles/illustrations/BenchmarkingIllustration";
import { Benchmarking } from "../Benchmarking/Benchmarking";
import { DashboardWidget } from "../ui/DashboardWidget/DashboardWidget";

interface BenchmarkingWidgetProps {
  inclusionScore?: number;
  scoreError?: boolean;
  scoreLoading?: boolean;
}
export const BenchmarkingWidget = ({
  inclusionScore,
  scoreError,
  scoreLoading = true,
}: BenchmarkingWidgetProps) => {
  const { t } = useTranslation("dashboardPage");
  return (
    <DashboardWidget
      title={t("benchmarkingWidget.title")}
      icon={BenchmarkingIllustration}
      className="BenchmarkingWidget"
    >
      <Benchmarking
        inclusionScore={inclusionScore}
        scoreError={scoreError}
        scoreLoading={scoreLoading}
      />
    </DashboardWidget>
  );
};
