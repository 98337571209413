import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { CardActionArea } from "@mui/material";
import classNames from "classnames";
import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "../../../components/ui/Card/Card";
import useTranslation from "../../../hooks/useTranslation";
import { IllustrationProps } from "../../../types/illustration";
import { UpgradeSubscriptionForm } from "../../UpgradeSubscriptionForm/UpgradeSubscriptionForm";
import { Button } from "../Button/Button";
import { Modal } from "../Modal/Modal";
import { Snackbar } from "../Snackbar/Snackbar";
import { Typography } from "../Typography/Typography";
import "./DashboardWidget.scss";

interface DashboardWidgetProps {
  children: ReactNode;
  className?: string;
  href?: string;
  icon: ({ className, iconOnly, size }: IllustrationProps) => JSX.Element;
  preview?: boolean;
  small?: boolean;
  title: string;
}

export const DashboardWidget = ({
  children,
  className,
  href,
  icon,
  preview = false,
  small,
  title,
}: DashboardWidgetProps) => {
  const { t } = useTranslation("dashboardPage");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const classes = classNames("DashboardWidget", className, {
    "DashboardWidget--preview": preview,
  });

  const containerClasses = classNames("DashboardWidget__container", {
    "DashboardWidget__container--small": small,
  });

  const clickable = !preview && href;

  const handleSuccessfulQuoteRequest = () => {
    setSnackBarOpen(true);
    setIsModalOpen(false);
  };

  return (
    <Card className={classes} containerClassName={containerClasses}>
      <CardActionArea
        className="DashboardWidget__header"
        component={Link}
        to={href || "#"}
        disabled={!clickable}
        disableRipple
      >
        {icon({})}
        <Typography tagVariant="h2" desktop="h3" color="coal">
          {title}
        </Typography>
        <div className="DashboardWidget__header__icon">
          {clickable && <KeyboardArrowRightIcon color="secondary" />}
        </div>
      </CardActionArea>
      {children}
      {preview && (
        <div className="DashboardWidget__content__previewOverlay">
          <div>
            <Button
              color="info"
              variant="contained"
              onClick={() => setIsModalOpen(true)}
            >
              {t("subscriptions.upgrade")}
            </Button>
          </div>
        </div>
      )}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} light>
        <UpgradeSubscriptionForm
          onSuccessfulRequestQuote={() => handleSuccessfulQuoteRequest()}
        />
      </Modal>
      <Snackbar
        open={snackBarOpen}
        onClose={() => setSnackBarOpen(false)}
        message={t("subscriptions.quoteRequestSent")}
        autoHideDuration={3000}
      />
    </Card>
  );
};
