import { AxiosInstance } from "axios";
import {
  GetSurveyChapterScoringResponse,
  GetSurveyDemographicResponse,
  GetSurveyQuestionsBreakdownResponse,
  GetSurveyRecommendationResponse,
  GetSurveyScoringDemographicResponse,
} from "../types/workplaceCulture";
import { OVERVIEW_FILTER_ID } from "../utils/WorkplaceCulture.utils";
import { getApiClient } from "../utils/api.utils";

export class WorkplaceCultureService {
  client: AxiosInstance;

  constructor() {
    this.client = getApiClient();
  }

  getSurveyScoring = async (dispatchToken: string, filterId?: string) => {
    const response = await this.client.get<GetSurveyScoringDemographicResponse>(
      `/workplace-culture/${dispatchToken}/scoring/${
        filterId && filterId !== OVERVIEW_FILTER_ID
          ? `?demographic_scope=${filterId}`
          : ""
      }`
    );

    return response.data;
  };

  getSurveyChapterScoring = async (
    dispatchToken: string,
    filterId?: string
  ) => {
    const response = await this.client.get<GetSurveyChapterScoringResponse>(
      `/workplace-culture/${dispatchToken}/scoring/chapters${
        filterId && filterId !== OVERVIEW_FILTER_ID
          ? `?demographic_scope=${filterId}`
          : ""
      }`
    );

    return response.data;
  };

  getSurveyQuestionsScoring = async (dispatchToken: string) => {
    const response = await this.client.get<GetSurveyQuestionsBreakdownResponse>(
      `/workplace-culture/${dispatchToken}/scoring/questions`
    );

    return response.data;
  };

  getSurveyDemographics = async (dispatchToken: string) => {
    const response = await this.client.get<GetSurveyDemographicResponse>(
      `/workplace-culture/${dispatchToken}/engagement/`
    );

    return response.data;
  };

  getRecommendations = async (dispatchToken: string) => {
    const response = await this.client.get<GetSurveyRecommendationResponse>(
      `/workplace-culture/${dispatchToken}/recommendations/`
    );

    return response.data;
  };
}
