import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AxiosResponse } from "axios";
import {
  Context,
  FC,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import User from "../models/user";
import { UserResponse } from "../types/user";
import {
  ACCESS_TOKEN_KEY,
  CLIENT_ID,
  USER,
  getApiClient,
  handleRefreshToken,
} from "../utils/api.utils";
import {
  ProtectedRoutes,
  PublicRoutes,
  isPublicRoute,
} from "../utils/routes.utils";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig = {
  auth: {
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_TENANT}`,
    clientId: process.env
      .REACT_APP_AUTHENTICATION_MICROSOFT_CLIENT_ID as string,
    redirectUri: process.env.REACT_APP_MICROSOFT_AUTH_CALLBACK_URL as string,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            process.env.REACT_APP_DEBUG_AUTH === "true" &&
              console.info(message);
            return;
          case LogLevel.Verbose:
            process.env.REACT_APP_DEBUG_AUTH === "true" &&
              console.debug(message);
            return;
          case LogLevel.Warning:
            process.env.REACT_APP_DEBUG_AUTH === "true" &&
              console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ["User.Read"],
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */

const msalInstance = new PublicClientApplication(msalConfig);

export interface AuthInfo {
  user: User | undefined;
}

export interface AuthResponse {
  access: string | undefined;
  user: UserResponse | undefined;
}

export interface RefreshAuthResponse {
  access: string | undefined;
  access_expiration: string | undefined;
}

const emptyAuthInfo: AuthInfo = {
  user: undefined,
};

export interface AuthClientProviders {
  google: {
    code: string;
    invite_token?: string;
  };
  microsoft: {
    access_token: string;
    id_token: string;
    invite_token?: string;
  };
}

export type AuthClientProviderValue =
  AuthClientProviders[keyof AuthClientProviders];

export interface IAuthContext {
  authInfo: AuthInfo;
  setAuthInfo: React.Dispatch<React.SetStateAction<AuthInfo>>;
  loadingAuth: boolean;
  onLogin: (
    provider: keyof AuthClientProviders,
    data: AuthClientProviderValue
  ) => Promise<AxiosResponse<AuthResponse>>;
  onLogout: () => Promise<AxiosResponse<any>>;
  isLoggedIn: () => boolean;
}

export const AuthContext: Context<IAuthContext> = createContext<IAuthContext>({
  authInfo: emptyAuthInfo,
  setAuthInfo: () => {},
  onLogin: () => new Promise(() => {}),
  onLogout: () => new Promise(() => {}),
  loadingAuth: false,
  isLoggedIn: () => false,
});

export const AuthProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [authInfo, setAuthInfo] = useState<AuthInfo>(emptyAuthInfo);
  const [loadingAuth, setLoadingAuth] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // if there is a user in localstorage, set it in the auth (react) context
    // to sync it with the rest of the app.
    const userStorage = localStorage.getItem(USER);
    const user = userStorage ? new User(JSON.parse(userStorage)) : undefined;
    if (user) {
      setAuthInfo({
        user: user,
      });
    }
    // if the user is not logged in, or there is no user in localstorage,
    // try to refresh the token. If that fails, the user is not logged in
    // and we can let them enter the login page.
    if (
      (!isLoggedIn() || !authInfo.user) &&
      !isPublicRoute(location.pathname)
    ) {
      handleRefreshToken()
        .then(() => {
          // Don't let logged in users enter the login page
          const currentPage =
            location.pathname === PublicRoutes.LOGIN
              ? ProtectedRoutes.DASHBOARD
              : location.pathname;
          navigate(currentPage);
        })
        .catch((err) => {
          // TODO: present user with an error message
        })
        .finally(() => {
          setLoadingAuth(false);
        });
    } else {
      setLoadingAuth(false);
      if (isLoggedIn()) {
        // Don't let logged in users enter the login page
        if (location.pathname === PublicRoutes.LOGIN) {
          navigate(ProtectedRoutes.DASHBOARD);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoggedIn = useCallback(() => {
    return !!localStorage.getItem(ACCESS_TOKEN_KEY);
  }, []);

  const handleLogin = useCallback(
    async (
      provider: keyof AuthClientProviders,
      data: AuthClientProviderValue
    ): Promise<AxiosResponse<AuthResponse>> => {
      setLoadingAuth(true);
      const providerSettings = {
        google: {
          url: "/auth/google/",
          config: {
            withCredentials: true,
          },
        },
        microsoft: {
          url: "/auth/microsoft/",
          config: {
            withCredentials: true,
          },
        },
      }[provider];

      const request = getApiClient(false).post<AuthResponse>(
        providerSettings.url,
        data,
        providerSettings.config
      );

      return new Promise<AxiosResponse<AuthResponse>>((resolve, reject) => {
        request
          .then((res) => {
            if (!res.data.user) {
              throw new Error("No user data in response");
            }

            const user = new User(res.data.user);

            setAuthInfo({
              user: user,
            });

            localStorage.setItem(ACCESS_TOKEN_KEY, res.data.access || "");
            localStorage.setItem(USER, JSON.stringify(user));

            resolve(res);
          })
          .catch((err) => {
            console.error("Error logging in", err);
            reject(err);
          })
          .finally(() => {
            setLoadingAuth(false);
          });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleLogout = useCallback(async () => {
    const request = getApiClient(false).post<{}>(
      "/auth/logoff/",
      new URLSearchParams({
        client_id: CLIENT_ID,
      }),
      {
        withCredentials: true,
      }
    );

    return new Promise<AxiosResponse<{}>>((resolve, reject) => {
      request
        .then((res) => {
          setAuthInfo(emptyAuthInfo);
          localStorage.removeItem(ACCESS_TOKEN_KEY);
          localStorage.removeItem(USER);
          resolve(res);
        })
        .catch((err) => {
          setAuthInfo(emptyAuthInfo);
          localStorage.removeItem(ACCESS_TOKEN_KEY);
          localStorage.removeItem(USER);
          // TODO send an error to sentry that a logout failure happened
          reject(err);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    authInfo,
    setAuthInfo,
    loadingAuth,
    onLogin: handleLogin,
    onLogout: handleLogout,
    isLoggedIn,
  };

  return (
    <MsalProvider instance={msalInstance}>
      <GoogleOAuthProvider
        clientId={
          process.env.REACT_APP_AUTHENTICATION_GOOGLE_CLIENT_ID as string
        }
      >
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      </GoogleOAuthProvider>
    </MsalProvider>
  );
};
