import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { SubscriptionService } from "../../api/SubscriptionService";
import useTranslation from "../../hooks/useTranslation";
import { RequestQuoteData } from "../../types/subscription";
import { getFormattedDate } from "../../utils/formatters";
import i18n from "../../utils/i18n";
import { Button } from "../ui/Button/Button";
import { Typography } from "../ui/Typography/Typography";
import "./UpgradeSubscriptionForm.scss";

interface UpgradeSubscriptionFormProps {
  onSuccessfulRequestQuote: () => void;
}

export const UpgradeSubscriptionForm = ({
  onSuccessfulRequestQuote,
}: UpgradeSubscriptionFormProps) => {
  const { t } = useTranslation("common");
  const subscriptionService = new SubscriptionService();
  const [requestQuoteData, setRequestQuoteData] = useState<RequestQuoteData>();

  const sendRequestQuote = useMutation({
    mutationFn: () => {
      return subscriptionService.requestQuote();
    },
    onSuccess: (data) => {
      setRequestQuoteData(data);
      if (data.wasCreated) {
        onSuccessfulRequestQuote();
      }
    },
  });

  return (
    <div className="UpgradeSubscriptionForm">
      <Typography desktop="h2">{t("subscriptions.upgrade")}</Typography>
      <Typography desktop="body1">{t("subscriptions.upgradeInfo")}</Typography>
      <div className="UpgradeSubscriptionForm__actions">
        <Button
          color="primary"
          variant="contained"
          onClick={() => sendRequestQuote.mutate()}
          disabled={sendRequestQuote.isLoading}
        >
          {t("subscriptions.requestQuote")}
        </Button>
        <a
          href="https://alda.co/book-a-demo-is"
          target="_blank"
          rel="noreferrer"
        >
          <Button color="secondary" variant="contained">
            {t("subscriptions.bookDemo")}
          </Button>
        </a>
      </div>
      {requestQuoteData && (
        <div>
          {!requestQuoteData.wasCreated && !requestQuoteData.isRepliedTo && (
            <Typography desktop="body2" color="error">
              {t("subscriptions.quoteRequestExists", {
                date: getFormattedDate(
                  requestQuoteData.requestedAt,
                  t,
                  i18n.language,
                  "LLL dd, yyyy HH:mm"
                ),
              })}
            </Typography>
          )}
          {!requestQuoteData.wasCreated && requestQuoteData.isRepliedTo && (
            <Typography desktop="body2" color="error">
              {t("subscriptions.quoteRequestReplied")}
            </Typography>
          )}
        </div>
      )}
      {sendRequestQuote.isError && (
        <Typography desktop="body2" color="error">
          {t("subscriptions.quoteRequestError")}
        </Typography>
      )}
    </div>
  );
};
