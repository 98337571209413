import {
  FormControl,
  Grid,
  SelectChangeEvent,
  Skeleton,
  Typography,
} from "@mui/material";
import classnames from "classnames";
import { useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { GetPayEquityResponse } from "../../types/payEquity";
import { EmptyState } from "../EmptyState/EmptyState";
import { NoDataState } from "../NoDataState/NoDataState";
import { PayEquityScale } from "../PayEquityScale/PayEquityScale";
import { Card } from "../ui/Card/Card";
import { Select } from "../ui/Select/Select";
import { Tab } from "../ui/Tabs/Tab";
import { TabPanel } from "../ui/Tabs/TabPanel";
import { Tabs } from "../ui/Tabs/Tabs";
import { PayFilter, generatePayFilters } from "./PayEquityOverview.utils";
import "./PayEquityOverview.scss";

interface PayEquityOverviewProps {
  payEquityData?: GetPayEquityResponse;
  isLoading?: boolean;
  error?: boolean;
  noIntegration?: boolean;
  isWidget?: boolean;
}

export enum ViewOption {
  "PERCENT" = "Percent",
  "DEVIATION" = "Deviation",
  "VALUE" = "Value",
}

const viewOptions = Object.values(ViewOption);

export const PayEquityOverview = ({
  payEquityData,
  isLoading,
  error,
  noIntegration,
  isWidget,
}: PayEquityOverviewProps) => {
  const { t } = useTranslation("payEquityPage");
  const [viewOption, setViewOption] = useState(ViewOption.DEVIATION);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleChangeTab = (newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const payFilters = payEquityData
    ? generatePayFilters(payEquityData, t, viewOption)
    : [];

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setViewOption(
      viewOptions.find((option) => option === event.target.value) ||
        ViewOption.DEVIATION
    );
  };

  const renderLoading = () => (
    <div className="PayEquityOverview__loading">
      <Skeleton height={50} />
      <Skeleton height={200} />
    </div>
  );

  const renderError = () => (
    <NoDataState title={t("error.title")} info={t("error.info")} />
  );

  const renderNoData = () => (
    <EmptyState
      title={t("noData.title")}
      info={t("noData.info")}
      href="/settings"
      actionInfo={t("noData.action")}
    />
  );

  const renderTabContent = () => {
    return payFilters.map((filter, i) => {
      return (
        <TabPanel
          selectedTabIndex={selectedTabIndex}
          index={i}
          key={`payFilters-${i}`}
          label="pay-equity-filters-tab"
        >
          {renderScale(filter)}
        </TabPanel>
      );
    });
  };

  const renderScale = (filter: PayFilter) => (
    <>
      <Typography color="secondary">{t("scale.info")}</Typography>
      <Typography color="secondary">
        {t("scale.currency", {
          currency: payEquityData?.currency,
          period: payEquityData?.pay_period.toLowerCase(),
        })}
      </Typography>
      <PayEquityScale
        className="PayEquityOverview__scale"
        payFilterData={filter}
        small={isWidget}
      />
    </>
  );

  const classes = classnames("PayEquityOverview", {
    "PayEquityOverview--widget": isWidget,
    "PayEquityOverview--noData": (error || !payEquityData) && !isLoading,
    "PayEquityOverview--loading": isLoading,
  });

  const containerClasses = classnames("PayEquityOverview--container", {
    "PayEquityOverview--widget": isWidget,
  });

  return (
    <Card
      className={classes}
      containerClassName={containerClasses}
      square={isWidget}
    >
      {noIntegration && renderNoData()}
      {!isWidget && !noIntegration && (
        <Grid container className="PayEquityOverview__header">
          <Grid item md={2}></Grid>
          <Grid item md={8} className="PayEquityOverview__header__title">
            <Typography variant="h2">
              {t("scale.title")}
              <Typography
                variant="handwrittenH2"
                color="error"
                sx={{ m: "0 0.5rem" }}
              >
                {t("scale.titleEmphasis")}
              </Typography>{" "}
              {t("scale.title2")}
            </Typography>
          </Grid>
          <Grid item md={2}>
            {!isWidget && (
              <FormControl
                size="small"
                className="PayEquityOverview__header__viewSelector"
                disabled={!payFilters.length}
              >
                <Select
                  labelId="pay-equity-display"
                  id="pay-equity-display"
                  value={viewOption}
                  label={t("scale.valueOptions.title")}
                  onChange={handleChange}
                  items={viewOptions}
                />
              </FormControl>
            )}
          </Grid>
        </Grid>
      )}
      {!error && payFilters.length > 0 && (
        <>
          <Tabs
            selectedTabIndex={selectedTabIndex}
            label="Pay-equity-scale"
            className="PayEquityOverview__filters"
            small={isWidget}
            withIndicator
          >
            {payFilters.map((filter, i) => {
              return (
                <Tab
                  key={filter.label}
                  selected={selectedTabIndex === i}
                  label={filter.label}
                  index={i}
                  onClick={handleChangeTab}
                  small={isWidget}
                  withIndicator
                />
              );
            })}
          </Tabs>
          {renderTabContent()}
        </>
      )}
      {isLoading && !noIntegration && renderLoading()}
      {(error || !payEquityData) &&
        !isLoading &&
        !noIntegration &&
        renderError()}
    </Card>
  );
};
