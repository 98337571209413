import {
  MoreVert as MoreVertIcon,
  Pageview as PageViewIcon,
  ZoomIn as ZoomInIcon,
} from "@mui/icons-material";
import {
  Grid,
  SelectChangeEvent,
  Skeleton,
  Switch,
  Typography,
} from "@mui/material";
import classnames from "classnames";
import { useEffect, useState } from "react";
import { WorkplaceCultureScale } from "../../components/WorkplaceCultureScale/WorkplaceCultureScale";
import { Card } from "../../components/ui/Card/Card";
import useTranslation from "../../hooks/useTranslation";
import { GetSurveyScoringDemographicResponse } from "../../types/workplaceCulture";
import {
  MAX_SCALE_VALUE,
  MIN_REQUIRED_ANSWERS,
  MIN_SCALE_VALUE,
  OVERVIEW_FILTER_ID,
  generateVisibleScores,
  getGroupedDemographicScopes,
  getMinMaxScoreValuesForFilter,
} from "../../utils/WorkplaceCulture.utils";
import { NoDataState } from "../NoDataState/NoDataState";
import { WorkplaceCultureDemographicFilter } from "../WorkplaceCultureDemographicFilter/WorkplaceCultureDemographicFilter";
import { WorkplaceCultureScaleSettings } from "../WorkplaceCultureScaleSettings/WorkplaceCultureScaleSettings";
import { Button } from "../ui/Button/Button";
import { Menu } from "../ui/Menu/Menu";
import { Tooltip } from "../ui/Tooltip/Tooltip";
import "./WorkplaceCultureOverview.scss";

interface WorkplaceCultureOverviewProps {
  hasEnoughAnswers: boolean;
  scoringData?: GetSurveyScoringDemographicResponse;
  isLoading?: boolean;
  error?: boolean;
  isWidget?: boolean;
  title?: string;
  selectedDemographicFilter?: string;
  onScoringRefetch?: (filterId: string) => void;
  isInChapter?: boolean;
}

export const WorkplaceCultureOverview = ({
  scoringData,
  hasEnoughAnswers = false,
  isLoading = true,
  isWidget = false,
  error = false,
  title,
  selectedDemographicFilter,
  onScoringRefetch,
  isInChapter = false,
}: WorkplaceCultureOverviewProps) => {
  const { t } = useTranslation("workplaceCulturePage");
  const [selectedFilters, setSelectedFilters] = useState([OVERVIEW_FILTER_ID]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [minScaleValue, setMinScaleValue] = useState(MIN_SCALE_VALUE);
  const [maxScaleValue, setMaxScaleValue] = useState(MAX_SCALE_VALUE);
  const [isAutoZoomOn, setIsAutoZoom] = useState(false);
  const [moreActionsAnchor, setMoreActionsAnchor] = useState<HTMLElement>();
  const [hideIsSkip, setHideIsSkip] = useState(true);

  // Make sure that the if auto zoom is selected the scale is updated when filters change
  useEffect(() => {
    if (isAutoZoomOn) {
      handleAutoZoom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMoreActionsAnchor(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setMoreActionsAnchor(undefined);
  };

  const handleAutoZoom = () => {
    if (!scoringData) {
      return;
    }

    const allScores = generateVisibleScores(
      scoringData.scores,
      MIN_SCALE_VALUE,
      MAX_SCALE_VALUE,
      selectedFilters,
      scoringData.id,
      isInChapter,
      hideIsSkip,
      t
    );

    const { minScoreValue, maxScoreValue } =
      getMinMaxScoreValuesForFilter(allScores);

    setMinScaleValue(minScoreValue);
    setMaxScaleValue(maxScoreValue);
    setIsAutoZoom(true);
  };

  const handleResetZoom = () => {
    setMinScaleValue(MIN_SCALE_VALUE);
    setMaxScaleValue(MAX_SCALE_VALUE);
    setIsAutoZoom(false);
  };

  const handleManualZoom = (newValue: number[]) => {
    setMinScaleValue(newValue[0]);
    setMaxScaleValue(newValue[1]);
    setIsAutoZoom(false);
  };

  const handleFilterClick = (filterId: string) => {
    // Don't allow to unselect the first filter
    if (filterId === OVERVIEW_FILTER_ID) {
      setSelectedFilters([OVERVIEW_FILTER_ID]);
      return;
    }

    if (selectedFilters.includes(filterId)) {
      const newFilters = selectedFilters.filter((id) => id !== filterId);

      // If no filters are selected, select the first one
      if (!newFilters.length) {
        setSelectedFilters([OVERVIEW_FILTER_ID]);
      } else {
        setSelectedFilters(newFilters);
      }
    } else {
      // if selectedFilters include 0, remove it before adding the new filter
      let newFilters = selectedFilters;
      if (newFilters.includes(OVERVIEW_FILTER_ID)) {
        newFilters = newFilters.filter((id) => id !== OVERVIEW_FILTER_ID);
      }
      setSelectedFilters([...newFilters, filterId]);
    }
  };

  const handleChangeFilter = (e: SelectChangeEvent<unknown>) => {
    const selectedValue = e.target.value as string;
    handleResetZoom();
    onScoringRefetch && onScoringRefetch(selectedValue);
  };

  const visibleScores =
    scoringData && hasEnoughAnswers
      ? generateVisibleScores(
          scoringData.scores,
          minScaleValue,
          maxScaleValue,
          selectedFilters,
          scoringData.id,
          isInChapter,
          hideIsSkip,
          t
        )
      : undefined;

  const demographicFilters =
    scoringData && getGroupedDemographicScopes(scoringData.scores);

  const renderLoading = () => (
    <Skeleton className="WorkplaceCultureOverview__loading" />
  );

  const renderError = () => (
    <NoDataState title={t("scale.error")} info={t("scale.errorInfo")} />
  );

  const renderNotEnoughData = (title: string, info: string) => (
    <NoDataState title={title} info={info} />
  );

  const renderTitle = () => {
    const menuItems = [
      {
        text: hideIsSkip
          ? t("scale.actions.toggleIsSkipOff")
          : t("scale.actions.toggleIsSkipOn"),
        icon: (
          <Switch
            checked={!hideIsSkip}
            onClick={() => setHideIsSkip(!hideIsSkip)}
            data-testid="toggle-is-skip"
          />
        ),
      },
    ];

    return (
      <Grid container className="WorkplaceCultureOverview__title">
        <Grid item md={2}>
          {!error && !isLoading && (
            <WorkplaceCultureDemographicFilter
              demographicFilters={demographicFilters}
              onChange={handleChangeFilter}
              selectedValue={selectedDemographicFilter}
              disabled={isLoading || error}
            />
          )}
        </Grid>
        <Grid item md={8}>
          {title && (
            <>
              <Typography variant="h5" color="secondary" sx={{ mb: 1 }}>
                {t("chapterPage.overview.title")}
              </Typography>
              <Typography variant="h2">{title}</Typography>
            </>
          )}
          {!title && (
            <Typography variant="h2">
              {t("scale.title")}
              <Typography
                variant="handwrittenH2"
                color="error"
                sx={{ m: "0 0.5rem" }}
              >
                {t("scale.titleEmphasis")}
              </Typography>
              {t("scale.title2")}
            </Typography>
          )}
        </Grid>
        <Grid item md={2} className="WorkplaceCultureOverview__title__actions">
          {!error && !isLoading && (
            <>
              <Tooltip
                title={
                  isAutoZoomOn
                    ? t("scale.actions.resetZoom")
                    : t("scale.actions.autoZoom")
                }
                arrow
              >
                <Button
                  onClick={isAutoZoomOn ? handleResetZoom : handleAutoZoom}
                  data-testid="auto-zoom-inclusion-scale"
                  aria-label="action on inclusion scale"
                  aria-controls="auto-zoom-inclusion-scale"
                  aria-haspopup="true"
                  small
                  icon
                  variant={isAutoZoomOn ? "contained" : "outlined"}
                  color="secondary"
                  className="WorkplaceCultureOverview__title__actions__autoZoom"
                  disabled={!visibleScores}
                >
                  <PageViewIcon />
                </Button>
              </Tooltip>
              <Tooltip title={t("scale.actions.zoom")} arrow>
                <Button
                  onClick={handleOpenModal}
                  data-testid="manual-zoom-inclusion-scale"
                  aria-label="action on inclusion scale"
                  aria-controls="manual-zoom-inclusion-scale"
                  aria-haspopup="true"
                  variant="outlined"
                  color="secondary"
                  small
                  icon
                  disabled={!visibleScores}
                >
                  <ZoomInIcon />
                </Button>
              </Tooltip>
              <Button
                onClick={handleOpenMenu}
                data-testid="more-scale-actions"
                aria-label="more actions on inclusion scale"
                aria-controls="more-scale-actions"
                aria-haspopup="true"
                variant="outlined"
                color="secondary"
                small
                icon
                disabled={!visibleScores}
              >
                <MoreVertIcon />
              </Button>
              <Menu
                id="more-actions-menu"
                anchorEl={moreActionsAnchor}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: 52,
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={!!moreActionsAnchor}
                onClose={handleCloseMenu}
                menuItems={menuItems}
              />
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderFilters = () => {
    if (!hasEnoughAnswers || error || isLoading || !scoringData) {
      return;
    }

    const availableFilters: {
      id: string;
      label: string;
      hasEnoughAnswers: boolean;
    }[] = [];
    availableFilters.push({
      id: OVERVIEW_FILTER_ID,
      label: t("scale.filters.overview.title"),
      hasEnoughAnswers: true,
    });

    // filter out filters that have a group that matches the selected demographic filter
    const filters = scoringData?.scores?.filters.filter((filter) =>
      filter.groups.every((group) => group.id !== selectedDemographicFilter)
    );

    filters?.forEach((filter, i) => {
      // check if groups are empty
      const hasEnoughAnswersForFilter = !!filter.groups?.length;
      availableFilters.push({
        id: filter.id,
        label: filter.label,
        hasEnoughAnswers: hasEnoughAnswersForFilter,
      });
    });

    return (
      <>
        {availableFilters.map((filter, i) => {
          return (
            <Button
              data-testid="workplace-culture-overview-filter-button"
              key={filter.label}
              className="WorkplaceCultureOverview__filterButton"
              onClick={() => handleFilterClick(filter.id)}
              color={i === 0 ? "primary" : "secondary"}
              small
              variant={
                selectedFilters.includes(filter.id) ? "contained" : "outlined"
              }
              disabled={!filter.hasEnoughAnswers}
            >
              {filter.label}
            </Button>
          );
        })}
      </>
    );
  };

  const renderScale = () => {
    if (!visibleScores) {
      return renderNotEnoughData(
        t("scale.noData.title"),
        t("scale.noData.info", { count: MIN_REQUIRED_ANSWERS })
      );
    }

    return (
      <>
        {!isWidget && (
          <>
            <Typography color="secondary">{visibleScores.info}</Typography>
            <Typography color="secondary">
              {visibleScores.missingGroups ? t("scale.missingScaleInfo") : ""}
            </Typography>
          </>
        )}
        {isWidget && (
          <Typography color="secondary">{t("scale.scaleInfo")}</Typography>
        )}

        <WorkplaceCultureScale
          values={visibleScores.scaleValues}
          average={visibleScores.averageValue}
          className="WorkplaceCultureOverview__scale"
          small={isWidget}
          minValue={minScaleValue}
          maxValue={maxScaleValue}
        />
      </>
    );
  };

  const classes = classnames("WorkplaceCultureOverview", {
    "WorkplaceCultureOverview--widget": isWidget,
    "WorkplaceCultureOverview--noData": !hasEnoughAnswers || error,
  });

  const containerClasses = classnames("WorkplaceCultureOverview--container", {
    "WorkplaceCultureOverview--widgetContainer": isWidget,
  });

  return (
    <Card
      className={classes}
      containerClassName={containerClasses}
      square={isWidget}
    >
      {!isWidget && (
        <>
          {renderTitle()}
          {renderFilters()}
        </>
      )}
      {!isLoading && !error && renderScale()}
      {isLoading && renderLoading()}
      {error && !isLoading && renderError()}
      <WorkplaceCultureScaleSettings
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        value={[minScaleValue, maxScaleValue]}
        handleChange={handleManualZoom}
        onResetZoom={handleResetZoom}
      />
    </Card>
  );
};
