import { SelectChangeEvent, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Select } from "../ui/Select/Select";
import { StepContainer } from "./StepContainer";

// TODO: How do we map this for the backend?
const numberOfEmployees = [
  { label: "<50", value: 49 },
  { label: "50-150", value: 50 },
  { label: "150-250", value: 150 },
  { label: "250-500", value: 250 },
  { label: "500-1000", value: 500 },
  { label: "1000+", value: 1000 },
];
interface NumberOfEmployeeStepType {
  companyName: string;
  numberOfEmployee: number;
  setStep: Dispatch<SetStateAction<number>>;
  setNumberOfEmployee: Dispatch<SetStateAction<number>>;
  step: number;
}

export const NumberOfEmployeeStep = ({
  companyName,
  numberOfEmployee,
  setStep,
  setNumberOfEmployee,
  step,
}: NumberOfEmployeeStepType) => {
  const handleNumberOfEmployeeChange = (event: SelectChangeEvent<unknown>) => {
    setNumberOfEmployee(event.target.value as number);
  };
  return (
    <StepContainer
      step={step}
      submitFunction={() => setStep(step + 1)}
      setStep={setStep}
    >
      <Typography variant="h1" sx={{ pb: 3 }}>
        Employees
      </Typography>
      <Typography
        variant="body1"
        sx={{ pb: 3 }}
        style={{ display: "inline-block", fontSize: "1rem" }}
      >
        How many employees do you have at{" "}
        <Typography
          variant="handwrittenH1"
          style={{ fontSize: "inherit" }}
          color="error"
        >
          {companyName}
        </Typography>
        ?
      </Typography>

      <Select
        labelId="number-of-employees"
        id="number-of-employees"
        value={numberOfEmployee.toString()}
        onChange={handleNumberOfEmployeeChange}
        required
        label="Number of employees"
        className="SignUp__form__dropdown"
        items={numberOfEmployees}
      />
    </StepContainer>
  );
};
