import { DataFilter } from "./filter";
import { SurveyQuestionResponse } from "./question";

export const BENCHMARKING_SCORE = 3.4;

export interface GetSurveyChapterScoringResponse {
  chapters: SurveyChapterScoring[];
}

export interface SurveyChapterScoring {
  id: string;
  description: string;
  name: string;
  demographic: GetSurveyScoringDemographicResponse;
}
export interface GetSurveyScoringDemographicResponse {
  id?: string;
  name?: string;
  scores: GetSurveyScoringResponse;
}

export interface GetSurveyScoringResponse {
  total_score?: number;
  filters: SurveyScoringFilter[];
}

export interface SurveyScoringFilter {
  label: string;
  id: string;
  groups: SurveyScoringGroup[];
}

export interface SurveyScoringGroup {
  label: string;
  score?: number;
  id: string;
  is_scope: boolean;
  is_skip: boolean;
}
export interface GetSurveyQuestionsBreakdownResponse {
  total_engagement: number;
  chapters: SurveyQuestionsChapterBreakdown[];
}

export interface SurveyQuestionsChapterBreakdown {
  id?: string;
  questions: SurveyQuestionResponseBreakdown[];
}

export interface SurveyQuestionResponseBreakdown
  extends Omit<SurveyQuestionResponse, "options" | "order"> {
  filters: SurveyQuestionAnswerFilter[];
}

export interface SurveyQuestionAnswerFilter {
  label: string;
  groups?: SurveyQuestionAnswerGroup[];
  is_custom: boolean;
}
export interface SurveyQuestionAnswerGroup {
  label: string;
  engagement: number;
  options: SurveyQuestionAnswer[];
}
export interface SurveyQuestionAnswer {
  count: number;
  reference_id: string;
  text: string;
}

export interface GetSurveyDemographicResponse {
  engagement: number;
  filters?: DataFilter<SurveyDemographicGroup>[];
}
export interface SurveyDemographicGroup {
  label: string;
  engagement?: number;
}

export interface AnswerDemographicGroup {
  label: string;
  count: number;
}

export interface GetSurveyRecommendationResponse {
  policy?: boolean;
  low_scores: string[];
  high_scores: string[];
  benchmark: boolean;
  insights?: string[];
}
