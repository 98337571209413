import { Skeleton } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import useTranslation from "../../hooks/useTranslation";
import { BENCHMARKING_SCORE } from "../../types/workplaceCulture";
import { NoDataState } from "../NoDataState/NoDataState";
import { ScoreArc } from "../ScoreArc/ScoreArc";
import { Typography } from "../ui/Typography/Typography";
import "./Benchmarking.scss";

interface BenchmarkingProps {
  inclusionScore?: number;
  scoreError?: boolean;
  scoreLoading?: boolean;
}

export const Benchmarking = ({
  inclusionScore,
  scoreError,
  scoreLoading = true,
}: BenchmarkingProps) => {
  const { t } = useTranslation("dashboardPage");
  const { authInfo } = useAuth();

  return (
    <div className="Benchmarking">
      <div className="Benchmarking__scoreSection">
        <Typography desktop="h2" className="Benchmarking__title">
          {t("benchmarking.scoreTitle", {
            companyName: authInfo?.user?.getCompanyName(),
            interpolation: { escapeValue: false },
          })}
        </Typography>
        {inclusionScore && <ScoreArc score={inclusionScore} />}
        {scoreLoading && <Skeleton height={50} width={"100%"} />}
        {scoreError && (
          <NoDataState info={t("benchmarking.scoreError")} light />
        )}
        {!inclusionScore && !scoreError && !scoreLoading && (
          <Typography
            tagVariant="p"
            desktop="body2"
            className="NoDataState__text__info"
            color={"secondary"}
          >
            {t("benchmarking.noInclusionScore")}
          </Typography>
        )}
      </div>
      <div className="Benchmarking__scoreSection">
        <Typography desktop="h2" className="Benchmarking__title">
          {t("benchmarking.indexTitle")}
        </Typography>
        {
          <ScoreArc
            score={BENCHMARKING_SCORE}
            linkLabel="Read More"
            href="https://alda.co/iceland-inclusion-index"
          />
        }
      </div>
    </div>
  );
};
