import { AxiosInstance, AxiosResponse } from "axios";
import { ErrorResponse } from "../types/error";
import { ErrorCodes } from "../types/pipeline";
import { RequestQuoteData, RequestQuoteResponse } from "../types/subscription";
import { getApiClient } from "../utils/api.utils";

export class SubscriptionService {
  client: AxiosInstance;

  constructor() {
    this.client = getApiClient();
  }

  requestQuote = async (): Promise<RequestQuoteData> => {
    const response: AxiosResponse<
      RequestQuoteResponse,
      ErrorResponse<ErrorCodes>
    > = await this.client.post("/subscription/price-quote/");

    if (response.status === 201) {
      return {
        isRepliedTo: response.data.is_replied_to,
        requestedAt: new Date(response.data.requested_at),
        wasCreated: true,
      };
    } else {
      return {
        isRepliedTo: response.data.is_replied_to,
        requestedAt: new Date(response.data.requested_at),
        wasCreated: false,
      };
    }
  };
}
