import Gradient1 from "../styles/illustrations/gradients/gradient_1.png";
import Gradient2 from "../styles/illustrations/gradients/gradient_2.png";
import Gradient3 from "../styles/illustrations/gradients/gradient_3.png";
import Gradient4 from "../styles/illustrations/gradients/gradient_4.png";
import { TrainingAudience, TrainingModuleResponse } from "../types/training";
import TrainingTouchpoint from "./trainingTouchpoint";

class TrainingModule {
  audience: TrainingAudience;
  description: string;
  duration: string;
  isReady: boolean;
  languages: string[];
  referenceId: string;
  tags: string[];
  thumbnail: string;
  title: string;
  toolbox: string;
  touchPoints: TrainingTouchpoint[];
  canView: boolean;
  canDownload: boolean;

  constructor(parameters: TrainingModuleResponse) {
    this.audience =
      parameters.audience === TrainingAudience.All
        ? TrainingAudience.All
        : TrainingAudience.Management;
    this.description = parameters.description;
    this.duration = parameters.duration;
    this.referenceId = parameters.reference_id;
    this.tags = parameters.tags;
    this.thumbnail = this.setThumbnail(parameters.reference_id);
    this.title = parameters.title;
    this.toolbox = parameters.toolbox;
    this.touchPoints = parameters.touchpoints?.map(
      (touchPointResponse) => new TrainingTouchpoint(touchPointResponse)
    );
    this.languages = parameters.languages;
    this.isReady = parameters.is_ready;
    this.canView = parameters.can_view; // Has the subscription to view the module and download material
    this.canDownload = parameters.can_download; // Has the subscription to download scorm files or toolboxes
  }

  setThumbnail = (id: string) => {
    const number = parseInt(id.split(".")?.[1]);

    if (this.audience === TrainingAudience.Management) {
      return Gradient1;
    } else if (number % 3 === 0) {
      return Gradient3;
    } else if (number % 2 === 0) {
      return Gradient2;
    }
    return Gradient4;
  };

  isAccessible = () => {
    return this.isReady && this.canView;
  };

  isComingSoon = () => {
    return !this.isReady && this.canView;
  };

  isNotAvailable = () => {
    return !this.canView;
  };
}

export default TrainingModule;
