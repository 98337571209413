import {
  Context,
  FC,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  AvailableLanguages,
  TAvailableSurveyLanguages as AvailableSurveyLanguages,
  SURVEY_LANGUAGE_LOCAL_STORAGE_KEY,
} from "../types/locale";
import { USER } from "../utils/api.utils";
import i18n from "../utils/i18n";
import { getLanguageConfig } from "../utils/locale.utils";

export enum LanguageDirection {
  RTL = "rtl", // right to left
  LTR = "ltr", // left to right
}

// Key for accessing in localstorage what language the user has selected
// Should later be stored in the database and no need to use local storage
export interface ILanguageContext {
  changeLanguage: (lang: AvailableLanguages) => void;
  getLanguageDirection: () => LanguageDirection;
  isPrimaryFontSupported: () => boolean;
}

export const LanguageContext: Context<ILanguageContext> =
  createContext<ILanguageContext>({
    changeLanguage: () => {},
    getLanguageDirection: () => LanguageDirection.LTR,
    isPrimaryFontSupported: () => true,
  });

export const LanguageProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [languageDirection, setLanguageDirection] = useState<
    LanguageDirection.LTR | LanguageDirection.RTL
  >(LanguageDirection.LTR);
  const [primaryFontSupported, setPrimaryFontSupported] =
    useState<boolean>(true);

  useEffect(() => {
    // On initial load, set the language to the one the user has stored
    // as a preference in local storage.
    // or check if there is a config for the language in the url
    const storedLanguage = getLanguageConfig(location.pathname, searchParams);

    if (storedLanguage === AvailableLanguages.ARABIC) {
      setLanguageDirection(LanguageDirection.RTL);
    } else {
      setLanguageDirection(LanguageDirection.LTR);
    }

    if (
      storedLanguage === AvailableLanguages.VIETNAMESE ||
      storedLanguage === AvailableLanguages.UKRAINIAN
    ) {
      setPrimaryFontSupported(false);
    } else {
      setPrimaryFontSupported(true);
    }

    i18n.changeLanguage(storedLanguage);
  }, [location.pathname, searchParams]);

  const changeLanguage = useCallback(
    (language: AvailableLanguages | AvailableSurveyLanguages) => {
      i18n.changeLanguage(language);
      const userStorage = localStorage.getItem(USER);
      if (userStorage) {
        const user = JSON.parse(userStorage);
        user.language = language;
        localStorage.setItem(USER, JSON.stringify(user));
      } else {
        localStorage.setItem(SURVEY_LANGUAGE_LOCAL_STORAGE_KEY, language);
      }
    },
    []
  );

  const getLanguageDirection = () => {
    return languageDirection;
  };

  const isPrimaryFontSupported = () => {
    return primaryFontSupported;
  };

  const value = {
    changeLanguage,
    getLanguageDirection,
    isPrimaryFontSupported,
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};
