import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useLanguages } from "../../hooks/useLanguages";
import useTranslation from "../../hooks/useTranslation";
import MultiSelectQuestion from "../../models/multiSelectQuestion";
import ScaleQuestion from "../../models/scaleQuestion";
import SingleSelectQuestion from "../../models/singleSelectQuestion";
import Survey from "../../models/survey";
import SurveyChapter from "../../models/surveyChapter";
import { LanguageDirection } from "../../providers/LanguageProvider";
import { getFormattedDate } from "../../utils/formatters";
import { SurveySegment } from "../SurveySegment/SurveySegment";
import { SurveyStart } from "../SurveyStart/SurveyStart";
import { Button } from "../ui/Button/Button";
import { Direction, IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./SurveyFlow.scss";

export interface SurveyFlowProps {
  survey: Survey;
  className: string;
  setShowThankYouScreen?: (show: boolean) => void;
  setSurveyProgress: (show: number) => void;
  onSurveySubmit: () => Promise<boolean>;
  progress?: number;
  isPreview?: boolean;
}

export const SurveyFlow = ({
  survey,
  className,
  setShowThankYouScreen,
  setSurveyProgress,
  onSurveySubmit,
  progress,
  isPreview = false,
}: SurveyFlowProps) => {
  const { t, i18n } = useTranslation("surveyPage");
  const [activeChapter, setActiveChapter] = useState<SurveyChapter>();
  const [activeQuestion, setActiveQuestion] = useState<
    ScaleQuestion | SingleSelectQuestion | MultiSelectQuestion
  >();
  const [isFinal, setIsFinal] = useState(false);
  const { getLanguageDirection } = useLanguages();
  const languageDirection = getLanguageDirection();

  const surveyIsActive = activeChapter || activeQuestion;
  let buttonLabel = surveyIsActive
    ? t("buttonAction.next")
    : t("buttonAction.start");

  if (isFinal) {
    buttonLabel = t("buttonAction.finish");
  } else if (activeChapter) {
    buttonLabel = t("buttonAction.continue");
  } else if (surveyIsActive && !isPreview) {
    buttonLabel = t("buttonAction.next");
  }

  if (isPreview && surveyIsActive) {
    buttonLabel = isFinal ? t("buttonAction.closePreview") : "";
  }

  useEffect(() => {
    setSurveyProgress(survey.getProgress());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChapter, activeQuestion]);

  const handleAnswerChange = (
    question: ScaleQuestion | SingleSelectQuestion | MultiSelectQuestion,
    answerId: string
  ) => {
    if (isPreview) {
      return;
    }

    question.setAnswer(answerId);
    setActiveQuestion({ ...question });
  };

  const handleNext = () => {
    const nextQuestion = survey.getNextSegment();

    if (nextQuestion instanceof SurveyChapter) {
      setActiveQuestion(undefined);
      setActiveChapter(nextQuestion);
    } else {
      if (survey.isLastSegment(nextQuestion?.referenceId)) {
        setIsFinal(true);
      }

      setActiveQuestion(nextQuestion);
      setActiveChapter(undefined);
    }
  };

  const handleBack = () => {
    const previousQuestion = survey.getPreviousSegment();

    if (!previousQuestion) {
      setActiveChapter(undefined);
      setActiveQuestion(undefined);
      return;
    }
    if (isFinal) {
      setIsFinal(false);
    }
    if (previousQuestion instanceof SurveyChapter) {
      setActiveQuestion(undefined);
      setActiveChapter(previousQuestion);
    } else {
      setActiveQuestion(previousQuestion);
      setActiveChapter(undefined);
    }

    document.getElementById("SurveyFlowContainer")?.scrollTo(0, 0);
  };

  const handleSubmit = async (force: boolean = false) => {
    if (!isFinal && !force) {
      survey.getNextSegment();
    }

    if (isFinal || force) {
      const success = await onSurveySubmit();

      if (success) {
        setActiveChapter(undefined);
        setActiveQuestion(undefined);
        setShowThankYouScreen?.(true);
        setSurveyProgress(0);
      }
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = ""; // Chrome requires returnValue to be set, the actual value doesn't matter
    };
    if (progress && progress > 0) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      // Remove event listener when component unmounts
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [progress]);

  const classes = classNames("SurveyFlow", className, {
    "SurveyFlow--start": !surveyIsActive,
  });
  const iconClasses = classNames("SurveyFlow__footer__icon", {
    "SurveyFlow__footer__icon--preview": !buttonLabel,
    "SurveyFlow__footer__icon--disabled":
      !activeQuestion?.hasAnswer() && !activeChapter && !isPreview,
  });
  const contentClasses = classNames("SurveyFlow__content", {
    "SurveyFlow__content--preview": isPreview,
    "SurveyFlow__content--rtl": languageDirection === LanguageDirection.RTL,
  });

  return (
    <div className={classes} id="SurveyFlowContainer">
      {!surveyIsActive && (
        <SurveyStart
          className={contentClasses}
          dueDate={getFormattedDate(survey.dueDate, t, i18n.language)}
          isPreview={isPreview}
          onStartClick={handleNext}
          buttonLabel={buttonLabel}
          isPublic={survey.isPublic}
        />
      )}
      {surveyIsActive && (
        <SurveySegment
          chapter={activeChapter}
          question={activeQuestion}
          className={contentClasses}
          onAnswerChange={handleAnswerChange}
          surveyAnswers={survey.getAnswers()}
          isPreview={isPreview}
        />
      )}
      {surveyIsActive && (
        <div className="SurveyFlow__footer">
          <div className="SurveyFlow__footer__actions">
            <div>
              {surveyIsActive && (
                <Button
                  className="SurveyFlow__footer__actions__previous"
                  color="secondary"
                  onClick={handleBack}
                >
                  <Icon type={IconTypes.Arrow} />
                </Button>
              )}
            </div>
            <Tooltip
              title={
                activeQuestion?.hasAnswer() || !!activeChapter || isPreview
                  ? ""
                  : t("selectOption")
              }
              enterTouchDelay={0}
              placement="top"
            >
              <span>
                <Button
                  className="SurveyFlow__footer__actions__next"
                  variant={isPreview ? "outlined" : "contained"}
                  disabled={
                    !activeQuestion?.hasAnswer() && !activeChapter && !isPreview
                  }
                  onClick={() => {
                    if (isFinal) {
                      handleSubmit();
                    } else {
                      handleNext();
                      document
                        .getElementById("SurveyFlowContainer")
                        ?.scrollTo(0, 0);
                    }
                  }}
                  color={
                    isFinal && !isPreview
                      ? "success"
                      : buttonLabel === ""
                      ? "secondary"
                      : "primary"
                  }
                >
                  {buttonLabel}
                  {surveyIsActive && !isFinal && (
                    <Icon
                      type={IconTypes.Arrow}
                      className={iconClasses}
                      direction={Direction.RIGHT}
                    />
                  )}
                </Button>
              </span>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};
