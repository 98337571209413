import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ErrorBoundary, Replay, init as SentryInit } from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import { BrowserRouter } from "react-router-dom";
import Frame from "./Frame";
import useTranslation from "./hooks/useTranslation";
import { ErrorPage } from "./pages/ErrorPage";
import { AuthProvider } from "./providers/AuthProvider";
import { DemoAuthProvider } from "./providers/DemoAuthProvider";
import { LanguageProvider } from "./providers/LanguageProvider";
import { grey2, theme } from "./styles/theme";
import { availableLanguages } from "./utils/formatters";
import "./App.scss";

HighchartsAccessibility(Highcharts);
HighchartsMore(Highcharts);
Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: "studiofeixen",
    },
  },
  title: {
    style: {
      fontFamily: "studiofeixen",
    },
  },
  tooltip: {
    backgroundColor: "white",
    borderColor: grey2,
    borderRadius: 8,
    style: {
      textTransform: "capitalize",
    },
  },
  credits: {
    enabled: false,
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error: any) => {
        // don't retry if 403 or 404
        return !(
          error?.response?.status === 403 ||
          error?.response?.status === 404 ||
          failureCount > 1
        );
      },
    },
  },
});

SentryInit({
  dsn: process.env.REACT_APP_SENTRY_DSN || "",
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [process.env.REACT_APP_API_HOST_URL || ""],
    }),
    new Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  release: process.env.REACT_APP_RELEASE,
  tracesSampleRate: parseFloat(
    process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE || "1.0"
  ),
});

const App = () => {
  const { i18n } = useTranslation();
  return (
    <ErrorBoundary fallback={<ErrorPage errorCode={500} />}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <AuthProvider>
              <DemoAuthProvider>
                <LanguageProvider>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={availableLanguages[i18n.language]}
                  >
                    <Frame />
                  </LocalizationProvider>
                  <ReactQueryDevtools initialIsOpen={false} />
                </LanguageProvider>
              </DemoAuthProvider>
            </AuthProvider>
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
