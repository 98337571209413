import useTranslation from "../../hooks/useTranslation";
import { coalColor } from "../../styles/theme";
import { Card } from "../ui/Card/Card";
import { Typography } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./SurveyNoticeCard.scss";

export const SurveyNoticeCard = () => {
  const { t } = useTranslation("surveyLaunchPage");
  const title = t("notice.title");
  const text = t("notice.text");

  return (
    <Card className="SurveyNoticeCard">
      <div className="SurveyNoticeCard__title">
        <Icon type={IconTypes.Message} color={coalColor} size={28} />
        <Typography desktop="h3" mobile="body1" tagVariant="h2">
          {title}
        </Typography>
      </div>
      <Typography
        desktop="body1"
        mobile="body2"
        tagVariant="p"
        className="SurveyNoticeCard__text"
      >
        {text}
      </Typography>
    </Card>
  );
};
