import useTranslation from "../../hooks/useTranslation";
import { SurveyEmailLanguage } from "../../types/survey";
import { sanitizeHTML } from "../../utils/survey.utils";
import "./SurveyEmailPreview.scss";

interface SurveyEmailPreviewProps {
  customMessage: string;
  surveyEmailLanguages: SurveyEmailLanguage[];
}

export const SurveyEmailPreview = ({
  customMessage,
  surveyEmailLanguages,
}: SurveyEmailPreviewProps) => {
  const { t } = useTranslation("surveyLaunchPage");
  const subjects = surveyEmailLanguages.map(
    (surveyEmailLanguage) => surveyEmailLanguage.subject
  );
  return (
    <div className="SurveyEmailPreview">
      <div className="SurveyEmailPreview__subject">
        <span className="SurveyEmailPreview__subject__title">
          {t("introductionEmail.previewModal.subject")}
        </span>
        {subjects.join(" | ")}
      </div>
      <div className="SurveyEmailPreview__email">
        {customMessage && (
          <blockquote className="SurveyEmailPreview__email__customMessage">
            {customMessage}
          </blockquote>
        )}
        <div className="SurveyEmailPreview__email__content">
          {surveyEmailLanguages.map((surveyEmailLanguage, index) => {
            let sanitizedHTML = sanitizeHTML(
              surveyEmailLanguage?.html_body || ""
            );
            return (
              <div
                key={surveyEmailLanguage.language_code}
                data-testid="survey-email-preview-html-body-lang"
              >
                <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                {surveyEmailLanguages.length - 1 !== index && "//"}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
