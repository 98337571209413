import {
  AVAILABLE_SURVEY_LANGUAGES,
  AvailableLanguages,
  TAvailableSurveyLanguages as AvailableSurveyLanguages,
  SURVEY_LANGUAGE_DEFAULT_LOCAL_STORAGE_KEY,
  SURVEY_LANGUAGE_LOCAL_STORAGE_KEY,
} from "../types/locale";
import { SURVEY_LANGUAGE_QUERY_KEY } from "../types/locale";
import { USER } from "./api.utils";
import { isSurveyRoute } from "./routes.utils";

export const getLanguageConfig = (
  route: string,
  searchParams: URLSearchParams
) => {
  const user = localStorage.getItem(USER);
  let selectedSurveyLanguage: AvailableSurveyLanguages | null = null;
  let defaultSurveyLanguage: AvailableSurveyLanguages | null = null;

  let userLanguage = user ? JSON.parse(user).language?.toLowerCase() : null;

  let storedLanguage: AvailableSurveyLanguages = AvailableLanguages.ENGLISH_GB;

  if (isSurveyRoute(route)) {
    const storedSurveyLanguage = localStorage
      .getItem(SURVEY_LANGUAGE_LOCAL_STORAGE_KEY)
      ?.toLowerCase();

    // check if storedSurveyLanguage is a valid language
    if (AVAILABLE_SURVEY_LANGUAGES.includes(storedSurveyLanguage as any)) {
      selectedSurveyLanguage = storedSurveyLanguage as AvailableSurveyLanguages;
    }

    const surveyLanguageInUrl = searchParams
      .get(SURVEY_LANGUAGE_QUERY_KEY)
      ?.toLowerCase();

    // check if surveyLanguageInUrl is a valid language
    if (AVAILABLE_SURVEY_LANGUAGES.includes(surveyLanguageInUrl as any)) {
      defaultSurveyLanguage = surveyLanguageInUrl as AvailableSurveyLanguages;
    }

    if (!selectedSurveyLanguage && defaultSurveyLanguage) {
      localStorage.setItem(
        SURVEY_LANGUAGE_DEFAULT_LOCAL_STORAGE_KEY,
        defaultSurveyLanguage
      );
    }

    if (userLanguage === AvailableLanguages.ENGLISH_US) {
      userLanguage = AvailableLanguages.ENGLISH_GB;
    }
  }

  if (userLanguage) {
    storedLanguage = userLanguage;
  } else if (selectedSurveyLanguage) {
    storedLanguage = selectedSurveyLanguage;
  } else if (defaultSurveyLanguage) {
    storedLanguage = defaultSurveyLanguage;
  }

  return storedLanguage;
};

export const handleBenchmarkingScore = (language: string, score: number) => {
  if (language === AvailableLanguages.ICELANDIC) {
    const icelandicScore = score.toString().replace(".", ",");
    return icelandicScore;
  } else {
    return score;
  }
};
