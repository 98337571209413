import useTranslation from "../../hooks/useTranslation";
import { MAX_SCALE_VALUE } from "../../utils/WorkplaceCulture.utils";
import getColorByScore from "../../utils/graphs";
import { handleBenchmarkingScore } from "../../utils/locale.utils";
import { ProgressArc } from "../ui/ProgressArc/ProgressArc";
import { Typography } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./ScoreArc.scss";

export interface ScoreArcProps {
  score: number;
  label?: string;
  linkLabel?: string;
  href?: string;
}

export const ScoreArc = ({ score, label, linkLabel, href }: ScoreArcProps) => {
  const { i18n } = useTranslation();

  const color = getColorByScore(score);
  const scoreLabel = handleBenchmarkingScore(i18n.language, score);

  const percentage = score / MAX_SCALE_VALUE;

  return (
    <div className="ScoreArc">
      <ProgressArc percentage={percentage} color={color} />
      <div className="ScoreArc__info">
        <Typography desktop="h1" className="ScoreArc__info__score">
          {scoreLabel}
        </Typography>
        {label && (
          <div className="ScoreArc__info__label">
            <Typography desktop="body1" color="secondary">
              {label}
            </Typography>
            <Icon type={IconTypes.Help} />
          </div>
        )}
        {linkLabel && href && (
          <a
            href={href}
            target="_blank"
            rel="noreferrer"
            className="ScoreArc__info__label"
          >
            <Typography desktop="body1" color="secondary">
              {linkLabel}
            </Typography>
            <Icon type={IconTypes.Help} />
          </a>
        )}
      </div>
    </div>
  );
};
