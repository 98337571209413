import { DataFilter } from "../types/filter";
import { ScaleValue } from "../types/scale";
import {
  GetSurveyScoringResponse,
  SurveyScoringFilter,
  SurveyScoringGroup,
} from "../types/workplaceCulture";

export interface ScoreFilter {
  scaleValues: ScaleValue[];
  averageValue: ScaleValue;
  info: string;
  missingGroups: boolean;
}

export interface GroupedSurveyDemographicScopes {
  [key: string]: SurveyScoringGroup[];
}

export const OVERVIEW_FILTER_GROUPS_COUNT = 6;
export const STEP_SIZE = 0.1;
export const MIN_REQUIRED_ANSWERS = 30;
export const MIN_SCALE_VALUE = 0;
export const MAX_SCALE_VALUE = 5;
export const OVERVIEW_FILTER_ID = "allResults";

export const generateVisibleScores = (
  data: GetSurveyScoringResponse,
  minScoreValue: number,
  maxScoreValue: number,
  selectedFilterIndexes: string[],
  selectedFilterId: string | undefined,
  isChapterScore: boolean,
  hideIsSkip: boolean,
  t: (str: string, options?: any) => string
): ScoreFilter => {
  const averageValue = {
    label: isChapterScore
      ? t("scale.filters.average.chapterTitle")
      : t("scale.filters.average.title"),
    score: Math.abs(data.total_score || 0),
  };

  const filtersWithoutSelectedFilter = data.filters.filter((filter) =>
    filter.groups.every((group) => group.id !== selectedFilterId)
  );

  if (selectedFilterIndexes.includes(OVERVIEW_FILTER_ID)) {
    const overviewFilterValues = getOverviewFilterValues(
      filtersWithoutSelectedFilter,
      hideIsSkip,
      t
    );

    const overviewFilter: ScoreFilter = {
      scaleValues: overviewFilterValues,
      averageValue: averageValue,
      info: t("scale.filters.overview.info"),
      missingGroups: overviewFilterValues.length < OVERVIEW_FILTER_GROUPS_COUNT,
    };
    // remove scale values from overview filter that are out of range
    overviewFilter.scaleValues = overviewFilter.scaleValues.filter(
      (scaleValue) =>
        scaleValue.score >= minScoreValue && scaleValue.score <= maxScoreValue
    );

    return overviewFilter;
  }

  // combine selected filters into one array
  const selectedFilters: SurveyScoringFilter[] =
    filtersWithoutSelectedFilter.filter((filter, i) =>
      selectedFilterIndexes.includes(filter.id)
    );

  // get all scale values that are in the selected filters
  const allGroupValues = selectedFilters.flatMap((filter) =>
    filter.groups.filter(
      (group) => !!group.score && (hideIsSkip ? !group.is_skip : true)
    )
  );

  const allScaleValues = transformScoringGroupsToScaleValue(
    allGroupValues,
    minScoreValue,
    maxScoreValue,
    t
  );

  // check if any of selectedFilters has missing groups
  let anyMissingGroups = false;
  selectedFilters.forEach((filter) => {
    anyMissingGroups = anyMissingGroups || hasMissingGroups(filter.groups);
  });

  return {
    scaleValues: allScaleValues,
    averageValue: averageValue,
    info: t(`scale.filters.${data.filters[1].label.toLowerCase()}.info`, ""),
    missingGroups: anyMissingGroups,
  };
};

export const getGroupedDemographicScopes = (
  scoringData: GetSurveyScoringResponse
): GroupedSurveyDemographicScopes => {
  const filters: GroupedSurveyDemographicScopes = {};

  scoringData.filters.forEach((filter) => {
    // filter out groups that are not is_scope
    const scopedGroups = filter.groups.filter((group) => group.is_scope);
    if (scopedGroups.length > 0) {
      filters[filter.label] = scopedGroups;
    }
  });

  return filters;
};

export const getOverviewFilterValues = (
  surveyScores: DataFilter<SurveyScoringGroup>[],
  hideIsSkip: boolean,
  t: (str: string, options?: any) => string
): ScaleValue[] => {
  const surveyScoresFlat = surveyScores
    .flatMap((scoringGroup) => scoringGroup.groups)
    .filter((scoringGroup) => !!scoringGroup?.score)
    .filter((scoringGroup) => (hideIsSkip ? !scoringGroup.is_skip : true))
    .sort((valueA, valueB) => (valueA?.score || 0) - (valueB?.score || 0));

  if (surveyScoresFlat.length < OVERVIEW_FILTER_GROUPS_COUNT) {
    const values: ScaleValue[] = [];

    surveyScoresFlat.map((scoreGroup) =>
      values.push({
        label: t(`scale.groups.${scoreGroup.label}`, scoreGroup.label),
        score: Math.abs(scoreGroup.score || 0),
      })
    );

    return values;
  }

  // Note: If more than 3 groups that have the lowest or highest score,
  // the one that first appear are chosen to appear.
  return [
    {
      label: t(
        `scale.groups.${surveyScoresFlat[0].label}`,
        surveyScoresFlat[0].label
      ),
      score: Math.abs(surveyScoresFlat[0].score || 0),
    },
    {
      label: t(
        `scale.groups.${surveyScoresFlat[1].label}`,
        surveyScoresFlat[1].label
      ),
      score: Math.abs(surveyScoresFlat[1].score || 0),
    },
    {
      label: t(
        `scale.groups.${surveyScoresFlat[2].label}`,
        surveyScoresFlat[2].label
      ),
      score: Math.abs(surveyScoresFlat[2].score || 0),
    },
    {
      label: t(
        `scale.groups.${surveyScoresFlat[surveyScoresFlat.length - 3].label}`,
        surveyScoresFlat[surveyScoresFlat.length - 3].label
      ),
      score: Math.abs(surveyScoresFlat[surveyScoresFlat.length - 3].score || 0),
    },
    {
      label: t(
        `scale.groups.${surveyScoresFlat[surveyScoresFlat.length - 2].label}`,
        surveyScoresFlat[surveyScoresFlat.length - 2].label
      ),
      score: Math.abs(surveyScoresFlat[surveyScoresFlat.length - 2].score || 0),
    },
    {
      label: t(
        `scale.groups.${surveyScoresFlat[surveyScoresFlat.length - 1].label}`,
        surveyScoresFlat[surveyScoresFlat.length - 1].label
      ),
      score: Math.abs(surveyScoresFlat[surveyScoresFlat.length - 1].score || 0),
    },
  ];
};

export const transformScoringGroupsToScaleValue = (
  surveyScoringGroups: SurveyScoringGroup[],
  minScoreValue: number,
  maxScoreValue: number,
  t: (str: string, options?: any) => string
): ScaleValue[] => {
  return (
    surveyScoringGroups
      .filter((group) => !!group.score)
      // filter values out of range
      .filter(
        (group) =>
          (group.score || MIN_SCALE_VALUE - 1) >= minScoreValue &&
          (group.score || MAX_SCALE_VALUE + 1) <= maxScoreValue
      )
      .map((group) => {
        return {
          label: t(`scale.groups.${group.label}`, group.label),
          score: group.score || MIN_SCALE_VALUE,
        };
      })
      .sort((a, b) => a.score - b.score)
  );
};

const hasMissingGroups = (surveyScoringGroups: SurveyScoringGroup[]) => {
  return (
    surveyScoringGroups.filter((group) => !!group.score).length !==
    surveyScoringGroups.length
  );
};

// Returns the minimum and maximum score values from the survey scoring data
export const getMinMaxScoreValuesForFilter = (filterData: ScoreFilter) => {
  const minScoreValue = Math.min(
    ...filterData.scaleValues.map((group) => group.score || MIN_SCALE_VALUE)
  );

  const maxScoreValue = Math.max(
    ...filterData.scaleValues.map((group) => group.score || MAX_SCALE_VALUE)
  );

  return {
    minScoreValue: Math.floor(minScoreValue),
    maxScoreValue: Math.ceil(maxScoreValue),
  };
};

export const createSegmentPieces = (
  minValue: number,
  maxValue: number,
  small: boolean
) => {
  const stepSize = small ? 1 : STEP_SIZE;
  const results: number[] = [];
  let currentValue = minValue + stepSize;
  while (currentValue <= maxValue) {
    results.push(parseFloat(currentValue.toFixed(1)));
    currentValue += stepSize;
  }

  // Check if maxValue should be included exactly, avoiding floating point precision issues
  if (
    parseFloat(maxValue.toFixed(1)) === maxValue &&
    !results.includes(maxValue)
  ) {
    results.push(maxValue);
  }
  return results;
};
