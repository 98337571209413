import { Grid } from "@mui/material";
import classnames from "classnames";
import { useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { OptionMapping } from "../../types/survey";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";
import { Input } from "../ui/Input/Input";
import { Typography } from "../ui/Typography/Typography";
import { TrashIcon } from "../ui/icons/TrashIcon";
import "./SurveyLaunchJobRoles.scss";

interface SurveyLaunchJobRolesItemProps {
  optionMapping: OptionMapping;
  optionMappingIndex: number;
  handleAddOption: (jobRoleIndex: number) => void;
  handleChangeOption: (
    value: string,
    index: number,
    jobRoleIndex: number
  ) => void;
  handleDeleteOption: (jobRoleIndex: number, optionIndex: number) => void;
  disabled?: boolean;
}
export const SurveyLaunchJobRolesItem = ({
  optionMapping,
  optionMappingIndex,
  handleAddOption,
  handleChangeOption,
  handleDeleteOption,
  disabled,
}: SurveyLaunchJobRolesItemProps) => {
  const { t } = useTranslation("surveyLaunchPage");
  const [errorMsg, setErrorMsg] = useState("");

  const handleKeyDown = (
    event: {
      key: string;
      preventDefault: () => void;
    },
    optionText: string
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      validateOption(optionText);
      handleAddOption(optionMappingIndex);
    }
  };

  const validateOption = (optionText: string) => {
    setErrorMsg("");
    const optionTexts = optionMapping.options.map((option) => option.text);
    const numberOfOptions = optionTexts.filter((o) => o === optionText).length;
    if (numberOfOptions > 1) {
      setErrorMsg(
        t("jobRoles.errors.alreadyInList", { value: optionText }) || ""
      );
    }
  };

  const classes = classnames("SurveyLaunchJobRolesItem", {
    "SurveyLaunchJobRolesItem--disabled": disabled,
  });
  return (
    <Grid container spacing={2} className={classes}>
      <Grid item xs={12} sm={6}>
        <Typography
          className="SurveyLaunchJobRolesItem__title"
          desktop="body1"
          weight="bold"
        >
          {optionMapping.label}
        </Typography>
        <Typography desktop="caption">
          {t(`jobRoles.${optionMapping.mapping.toLocaleLowerCase()}.info`)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className="SurveyLaunchJobRolesItem__options">
        <Typography desktop="caption" color="secondary">
          {t(
            `jobRoles.${optionMapping.mapping.toLocaleLowerCase()}.placeholder`
          )}
        </Typography>
        {optionMapping.options.map((option, i) => (
          <div
            className="SurveyLaunchJobRolesItem__options__inputContainer"
            key={`job-role-option${i}`}
          >
            <Input
              value={option.text}
              fullWidth
              onChange={(e) => {
                setErrorMsg("");
                handleChangeOption(e.target.value, i, optionMappingIndex);
              }}
              onKeyDown={(e) => handleKeyDown(e, option.text)}
              onBlur={() => validateOption(option.text)}
            />
            <Button
              variant="contained"
              color="secondary"
              icon
              onClick={() => handleDeleteOption(optionMappingIndex, i)}
            >
              <TrashIcon size={16} />
            </Button>
          </div>
        ))}
        <Card
          className="SurveyLaunchJobRolesItem__options__card"
          inactive
          onClick={() => handleAddOption(optionMappingIndex)}
        >
          + {t("jobRoles.addOption")}
        </Card>
        {errorMsg && (
          <Typography
            desktop="caption"
            color="error"
            className="SurveyLaunchJobRolesItem__options__error"
          >
            {errorMsg}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
