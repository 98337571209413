import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { CompanyService } from "../api/CompanyService";
import { Company } from "../components/Company/Company";
import { Integration } from "../components/Integration/Integration";
import { TeamMembers } from "../components/TeamMembers/TeamMembers";
import { Typography } from "../components/ui/Typography/Typography";
import { useAuth } from "../hooks/useAuth";
import useTranslation from "../hooks/useTranslation";
import {
  CompanyResponse,
  ICompany,
  TeamMembersResponse,
} from "../types/company";
import { SubscriptionActions } from "../types/user";
import "./SettingsPage.scss";

export const SettingsPage = () => {
  const { t } = useTranslation("settingsPage");
  const companyService = new CompanyService();
  const [company, setCompany] = useState<ICompany>();
  const { authInfo } = useAuth();

  const {
    data: companyData,
    isLoading,
    error,
  } = useQuery<CompanyResponse, AxiosError>(["company"], () =>
    companyService.getCompany()
  );

  const hasAccessToIntegrations = authInfo.user
    ? authInfo.user.hasAccess(SubscriptionActions.HR_INTEGRATION)
    : true;

  const hasAccessToTeamMembers = authInfo.user
    ? authInfo.user.hasAccess(SubscriptionActions.TEAM_MEMBERS)
    : true;

  const {
    data: teamMembersData,
    isLoading: teamMembersLoading,
    error: teamMembersError,
    refetch: handleUserInviteRefetch,
  } = useQuery<TeamMembersResponse, AxiosError>(["teamMembers"], () =>
    companyService.getTeamMembers()
  );

  useEffect(() => {
    if (companyData) {
      setCompany({
        name: companyData.name,
        industry: companyData.industry,
        numberOfEmployees: companyData.number_of_employees,
        subscriptions: companyData.subscriptions,
      });
    }
  }, [companyData]);

  return (
    <div className="SettingsPage">
      <Typography tagVariant="h1" desktop="h3" className="SettingsPage__title">
        {t("title.company")}
      </Typography>
      <Company company={company} isLoading={isLoading} error={!!error} />
      {hasAccessToTeamMembers && (
        <>
          <Typography
            tagVariant="h2"
            desktop="h3"
            className="SettingsPage__title"
          >
            {t("title.team")}
          </Typography>
          <TeamMembers
            teamMembers={teamMembersData?.members}
            isLoading={teamMembersLoading}
            isError={!!teamMembersError}
            onUserInviteUpdate={handleUserInviteRefetch}
          />
        </>
      )}
      {hasAccessToIntegrations && (
        <>
          <Typography
            tagVariant="h2"
            desktop="h3"
            className="SettingsPage__title"
          >
            {t("title.integrations")}
          </Typography>
          <Integration />
        </>
      )}

      <Typography
        tagVariant="p"
        desktop="body2"
        color="secondary"
        className="SettingsPage__version"
      >
        {process.env.REACT_APP_RELEASE}
      </Typography>
    </div>
  );
};
