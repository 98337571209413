import { useEffect, useRef, useState } from "react";
import { orangeColor } from "../../../styles/theme";
import "./ProgressArc.scss";

export interface ProgressArcProps {
  percentage: number; // number between 0 and 1, 1 being 100%
  color?: string;
  size?: number; // Diameter of the SVG
  strokeWidth?: number;
  ariaLabel?: string; // Accessibility label
}

export const ProgressArc = ({
  percentage,
  color = orangeColor,
  size = 200,
  strokeWidth = 24,
  ariaLabel = "Progress arc showing progress",
}: ProgressArcProps) => {
  const progressArcRef = useRef<SVGPathElement | null>(null);
  const arcTotalLength = 276; // pre-calculated total length of the arc
  const [strokeDashoffset, setStrokeDashoffset] = useState<string>(`${276}`);

  useEffect(() => {
    if (progressArcRef.current) {
      const strokeLength = arcTotalLength * percentage;
      setStrokeDashoffset((arcTotalLength - strokeLength).toString());
    }
  }, [percentage]);

  return (
    <svg
      width={size}
      height={(size * 132) / 200} // Maintain aspect ratio
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={ariaLabel}
      preserveAspectRatio="xMidYMid meet"
      className="ProgressArc"
    >
      <path
        opacity="0.4"
        d="M12 100C12 76.6609 21.2714 54.2778 37.7746 37.7746C54.2778 21.2714 76.6609 12 100 12C123.339 12 145.722 21.2714 162.225 37.7746C178.729 54.2778 188 76.6609 188 100"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        ref={progressArcRef}
        d="M12 100C12 76.6609 21.2714 54.2778 37.7746 37.7746C54.2778 21.2714 76.6609 12 100 12C123.339 12 145.722 21.2714 162.225 37.7746C178.729 54.2778 188 76.6609 188 100"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeDashoffset={strokeDashoffset}
        strokeDasharray={arcTotalLength.toString()}
        className="ProgressArc__progress"
      />
    </svg>
  );
};
