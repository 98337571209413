import { AxiosInstance } from "axios";
import { CompanyResponse, TeamMembersResponse } from "../types/company";
import { getApiClient } from "../utils/api.utils";

export class CompanyService {
  client: AxiosInstance;

  constructor() {
    this.client = getApiClient();
  }

  getCompany = async () => {
    const response = await this.client.get<CompanyResponse>("/company/");
    return response.data;
  };

  updateCompanyDetails = async (
    name: string,
    numberOfEmployees: number,
    industry: string,
    preferredCurrency: string
  ) => {
    const response = await this.client.post(`/company/update/`, {
      name: name,
      number_of_employees: numberOfEmployees,
      industry: industry,
      preferred_currency: preferredCurrency,
    });

    return response.data;
  };

  acceptTermsOfService = async () => {
    const response = await this.client.post(
      `/company/accept-terms-of-service/`
    );
    return response.data;
  };

  acceptDataProcessingAgreement = async () => {
    const response = await this.client.post(
      `/company/accept-data-processing-agreement/`
    );
    return response.data;
  };

  acceptAdditionalDataProcessingAgreement = async () => {
    const response = await this.client.post(
      `/company/accept-additional-data-processing-agreement/`
    );
    return response.data;
  };

  getTeamMembers = async () => {
    const response = await this.client.get<TeamMembersResponse>(
      "/company/members/"
    );
    return response.data;
  };

  inviteMembers = async (emails: string[]) => {
    const response = await this.client.post("/company/members/invite/", {
      emails: emails,
    });
    return response.data;
  };
}
