import { secondaryColor } from "../../../styles/theme";
import { IconProps } from "./";

export const ClockIcon = ({
  small = false,
  color = secondaryColor,
}: IconProps) => {
  return (
    <>
      {small ? (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 5C8.25 6.75 8 8 8 8C8 8 9.25 8.25 10 8"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.5 7.05233C13.5 3.63706 8.94828 2.5 7.43103 2.5C5.91379 2.5 2.5 5.53216 2.5 8.18949C2.5 12.4345 6.2931 13.4961 8.18966 13.4963C10.2126 13.6225 13.5 10.4676 13.5 7.05233Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      ) : (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 10C16.5 13.5 16 16 16 16C16 16 18.5 16.5 20 16"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27 14.1047C27 7.27412 17.8966 5 14.8621 5C11.8276 5 5 11.0643 5 16.379C5 24.869 12.5862 26.9923 16.3793 26.9927C20.4253 27.245 27 20.9352 27 14.1047Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      )}
    </>
  );
};
