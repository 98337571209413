import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { IntegrationService } from "../api/IntegrationService";
import { PipelineService } from "../api/PipelineService";
import { PipelineOverview } from "../components/PipelineOverview/PipelineOverview";
import { PipelineSetup } from "../components/PipelineSetup/PipelineSetup";
import { Button } from "../components/ui/Button/Button";
import { Card } from "../components/ui/Card/Card";
import { Modal } from "../components/ui/Modal/Modal";
import { Typography } from "../components/ui/Typography/Typography";
import useTranslation from "../hooks/useTranslation";
import { ErrorResponse } from "../types/error";
import { IntegrationResponse } from "../types/integration";
import { ErrorCodes, PipelineResponse } from "../types/pipeline";
import "./PipelinePage.scss";

export const PipelinePage = () => {
  const { t } = useTranslation("pipelinePage");
  const integrationService = new IntegrationService();
  const pipelineService = new PipelineService();
  const [errorCode, setErrorCode] = useState<string>("");
  const [modalOpen, setModalOpen] = useState(false);
  const [setupSuccess, setSetupSuccess] = useState(false);

  const { error: integrationError } = useQuery<IntegrationResponse, AxiosError>(
    ["integration"],
    () => integrationService.getIntegration()
  );

  const {
    data: pipelineData,
    isLoading: pipelineIsLoading,
    error: pipelineError,
  } = useQuery<PipelineResponse, AxiosError>(["pipeline", setupSuccess], () =>
    pipelineService.getPipeline()
  );

  useEffect(() => {
    const errorData = pipelineError?.response
      ?.data as ErrorResponse<ErrorCodes>;

    setErrorCode(errorData?.extra?.code || "");
  }, [pipelineError]);

  return (
    <div className="PipelinePage">
      {errorCode === ErrorCodes.NoJobLevel ? (
        <Card className="PipelinePage__setupCard">
          <Typography tagVariant="h3" desktop="h3">
            {t("setup.info")}
          </Typography>
          <Button variant="contained" onClick={() => setModalOpen(true)}>
            <Typography tagVariant="h3" desktop="h3">
              {t("actions.setup")}
            </Typography>
          </Button>
        </Card>
      ) : (
        <PipelineOverview
          pipelineData={pipelineData}
          isLoading={pipelineIsLoading}
          error={!!pipelineError}
          noIntegration={integrationError?.response?.status === 404}
        />
      )}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        className="PipelinePage__setupModal"
        light
      >
        <PipelineSetup
          onClose={() => setModalOpen(false)}
          setSetupSuccess={setSetupSuccess}
        />
      </Modal>
    </div>
  );
};
