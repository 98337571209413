import { MoreVert } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Grid, IconButton } from "@mui/material";
import { Box, CardActionArea, Skeleton } from "@mui/material";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";
import SurveyDispatch from "../../models/surveyDispatch";
import { ActiveStatusIllustration } from "../../styles/illustrations/ActiveStatusIllustration";
import { DocumentIllustration } from "../../styles/illustrations/DocumentIllustration";
import { grey4 } from "../../styles/theme";
import { SurveyDispatchStatus } from "../../types/survey";
import { getFormattedDate } from "../../utils/formatters";
import { ProtectedRoutes } from "../../utils/routes.utils";
import { SurveyPreviewCard } from "../SurveyPreviewCard/SurveyPreviewCard";
import { SurveyPublicLink } from "../SurveyPublicLink/SurveyPublicLink";
import { SurveyRecipients } from "../SurveyRecipients/SurveyRecipients";
import { SurveySettings } from "../SurveySettings/SurveySettings";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";
import { CircularProgressBar } from "../ui/CircularProgressBar/CircularProgressBar";
import { IMenuItem, Menu } from "../ui/Menu/Menu";
import { Modal } from "../ui/Modal/Modal";
import { Typography } from "../ui/Typography/Typography";
import { EditIcon } from "../ui/icons/EditIcon";
import { PreviewIcon } from "../ui/icons/PreviewIcon";
import { ShareIcon } from "../ui/icons/ShareIcon";
import "./SurveyStatus.scss";

interface SurveyStatusProps {
  survey?: SurveyDispatch;
  isLoading?: boolean;
  noSurvey?: boolean;
  error?: boolean;
  isWidget?: boolean;
  onClick?: () => void;
  onSurveyUpdate: () => void;
}

export const SurveyStatus = ({
  survey,
  isLoading = false,
  noSurvey = false,
  error = false,
  isWidget = false,
  onClick,
  onSurveyUpdate,
}: SurveyStatusProps) => {
  const { t, i18n } = useTranslation("workplaceCulturePage");
  const [isModuleOpen, setIsModuleOpen] = useState(false);
  const [dueDate, setDueDate] = useState("");
  const [anchor, setAnchor] = useState<HTMLElement>();
  const [isSurveySetting, setIsSurveySetting] = useState(false);
  const [isPreviewSurvey, setIsPreviewSurvey] = useState(false);
  const [isShareSurvey, setIsShareSurvey] = useState(false);
  const surveyIsClickable =
    survey && (survey.isReady() || survey.isInProgress());
  useEffect(() => {
    if (survey?.dueDate) {
      setDueDate(getFormattedDate(survey.dueDate, t, i18n.language));
    }
  }, [survey?.dueDate, t, i18n.language]);

  const shouldShowEngagement =
    !isWidget &&
    !noSurvey &&
    !error &&
    !survey?.isReady() &&
    !survey?.isInProgress();

  const shouldShowDueDate = !isWidget && survey?.isActive();

  const menuItems: IMenuItem[] = [
    {
      text: t("settings.dueDate"),
      icon: <EditIcon size={24} color={grey4} />,
      onClick: () => {
        setIsModuleOpen(true);
        setIsSurveySetting(true);
      },
    },
    {
      text: survey?.isPublic
        ? t("settings.share")
        : t("settings.addRecipients"),
      icon: <ShareIcon size={24} color={grey4} />,
      onClick: () => {
        setIsModuleOpen(true);
        setIsShareSurvey(true);
      },
    },
    {
      text: t("settings.preview"),
      icon: <PreviewIcon size={24} color={grey4} />,
      onClick: () => {
        setIsModuleOpen(true);
        setIsPreviewSurvey(true);
      },
    },
  ];

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchor(e.currentTarget);
  };

  const handleModalClose = () => {
    setIsModuleOpen(false);
    setIsPreviewSurvey(false);
    setIsShareSurvey(false);
    setIsSurveySetting(false);
  };

  const renderIcon = () => {
    if (isLoading) {
      return (
        <Skeleton
          variant="rectangular"
          className="SurveyStatus__card__content__title__icon"
        />
      );
    }

    if (
      (survey?.status === SurveyDispatchStatus.Ready ||
        survey?.status === SurveyDispatchStatus.InProgress) &&
      !error
    ) {
      return (
        <ActiveStatusIllustration className="SurveyStatus__card__content__title__icon" />
      );
    }

    if (survey?.status === SurveyDispatchStatus.Active) {
      return (
        <CircularProgressBar
          className="SurveyStatus__card__content__title__icon"
          progress={survey?.getProgress() || 0}
        />
      );
    }

    if (survey?.status === SurveyDispatchStatus.Latest) {
      return (
        <DocumentIllustration
          className="SurveyStatus__card__content__title__icon"
          size={32}
        />
      );
    }
  };

  const renderTitle = () => {
    if (isLoading) {
      return <Skeleton variant="text" width="150px" />;
    }

    if (error) {
      return <Typography desktop="h3">{t(`status.error`)}</Typography>;
    }

    if (noSurvey) {
      return (
        <div className="SurveyStatus__card__content__noSurveyInfo">
          <Typography
            desktop="h2"
            className="SurveyStatus__card__content__noSurveyInfo__title"
          >
            {t(`status.noSurvey`)}
          </Typography>
          <Typography desktop="body2">{t(`status.detail`)}</Typography>
        </div>
      );
    }

    return (
      <Typography desktop={isWidget ? "body2" : "h2"}>
        {t(`status.${survey?.status || "error"}`)}
      </Typography>
    );
  };

  const renderMenu = () => (
    <Box>
      <div className="SurveyStatus__card__actions">
        <IconButton
          size="small"
          aria-label="survey status edit"
          aria-controls="survey-status-edit"
          aria-haspopup="true"
          onClick={handleOpenMenu}
        >
          <MoreVert className="SurveyStatus__card__actions__edit__icon" />
        </IconButton>
      </div>
      <Menu
        id="survey-status-edit"
        anchorEl={anchor}
        onClose={() => setAnchor(undefined)}
        menuItems={menuItems}
        open={!!anchor}
        sx={{ display: "block" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: 57,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        light
      />
    </Box>
  );

  const renderShareSurvey = () => {
    if (survey?.isPublic) {
      return <SurveyPublicLink surveyDispatch={survey} />;
    }
    return (
      <SurveyRecipients
        recipients={survey?.recipients}
        onSurveyUpdate={onSurveyUpdate}
      />
    );
  };

  const classes = classNames("SurveyStatus__card", {
    "SurveyStatus__card--clickable": surveyIsClickable,
    "SurveyStatus__card--widget": isWidget,
    "SurveyStatus__card--noSurvey": noSurvey,
  });

  let titleSize = 11;
  if (shouldShowEngagement) {
    titleSize = titleSize - 2;
  }
  if (shouldShowDueDate) {
    titleSize = titleSize - 2;
  }
  if (!surveyIsClickable) {
    titleSize = titleSize + 1;
  }

  const engagementPercentage =
    survey?.recipients && survey.recipients.length > 0
      ? Math.round((survey?.engagement / survey.recipients.length) * 100)
      : 0;

  return (
    <div className="SurveyStatus">
      <Card className={classes}>
        <CardActionArea
          className="SurveyStatus__card__content"
          component={Link}
          to={ProtectedRoutes.LAUNCH}
          disabled={!surveyIsClickable}
          disableRipple
        >
          <Grid container>
            <Grid
              xs={titleSize}
              item
              className="SurveyStatus__card__content__title"
            >
              {renderIcon()}
              <div>
                {renderTitle()}
                {isLoading && <Skeleton variant="text" width="150px" />}
                {!isLoading && (
                  <>
                    <Typography desktop={"body2"} color="secondary">
                      {t(`statusDate.${survey?.getStatusLabel(error)}`, {
                        date: survey?.getStatusDateFormatted(t, i18n.language),
                      })}
                    </Typography>
                    {isWidget &&
                      (survey?.isActive() ||
                        survey?.status === SurveyDispatchStatus.Latest) && (
                        <Typography desktop={"body2"} color="secondary">
                          {t("info.engagementResponses", {
                            count: survey?.engagement || 0,
                          })}
                          {engagementPercentage <= 100 &&
                            !survey?.isPublic &&
                            ` (${engagementPercentage}%)`}
                        </Typography>
                      )}
                  </>
                )}
              </div>
            </Grid>
            {shouldShowDueDate && (
              <Grid xs={2} item className="SurveyStatus__card__content__info">
                {survey?.isActive() && (
                  <div>
                    <Typography
                      desktop="body2"
                      color="secondary"
                      className="SurveyStatus__card__content__info__title"
                    >
                      {t(`info.dueDate`)}
                    </Typography>
                    {!isLoading && (
                      <Typography desktop="body2">
                        {survey.dueDate && dueDate}
                      </Typography>
                    )}
                    {isLoading && <Skeleton variant="text" />}
                  </div>
                )}
              </Grid>
            )}
            {shouldShowEngagement && (
              <Grid sm={2} item className="SurveyStatus__card__content__info">
                {!noSurvey &&
                  !error &&
                  !survey?.isReady() &&
                  !survey?.isInProgress() && (
                    <div>
                      <Typography
                        desktop="body2"
                        color="secondary"
                        className="SurveyStatus__card__content__info__title"
                      >
                        {t(`info.engagement`)}
                      </Typography>
                      {!isLoading && (
                        <Typography desktop="body2">
                          {t("info.engagementResponses", {
                            count: survey?.engagement || 0,
                          })}
                          {engagementPercentage <= 100 &&
                            !survey?.isPublic &&
                            ` (${engagementPercentage}%)`}
                        </Typography>
                      )}
                      {isLoading && <Skeleton variant="text" />}
                    </div>
                  )}
              </Grid>
            )}
            {surveyIsClickable && (
              <Grid xs={1} item className="SurveyStatus__card__content__info">
                <KeyboardArrowRightIcon
                  color={noSurvey ? "primary" : "secondary"}
                />
              </Grid>
            )}
          </Grid>
        </CardActionArea>
        {survey?.isActive() && renderMenu()}
        {noSurvey && !isWidget && (
          <div className="SurveyStatus__card__actions">
            <Button
              color="info"
              variant="contained"
              disabled={isLoading}
              href={ProtectedRoutes.LAUNCH}
              onClick={onClick}
              className="SurveyStatus__card__actions__setUpSurvey"
            >
              {t(`actions.send`)}
            </Button>
          </div>
        )}
      </Card>

      <Modal
        open={isModuleOpen}
        onClose={handleModalClose}
        light
        className="SurveyStatus__modal"
      >
        {isSurveySetting && survey && <SurveySettings dispatch={survey} />}
        {isPreviewSurvey && (
          <SurveyPreviewCard
            surveyId={survey?.surveyId}
            isLoading={isLoading}
          />
        )}
        {isShareSurvey && renderShareSurvey()}
      </Modal>
    </div>
  );
};
