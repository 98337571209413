import { Grid } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { SurveyService } from "../api/SurveyService";
import { SurveyLaunchSteps } from "../components/SurveyLaunchSteps/SurveyLaunchSteps";
import { SurveyNoticeCard } from "../components/SurveyNoticeCard/SurveyNoticeCard";
import { SurveyStatus } from "../components/SurveyStatus/SurveyStatus";
import { Button } from "../components/ui/Button/Button";
import { LoadingIndicator } from "../components/ui/LoadingIndicator/LoadingIndicator";
import { Typography } from "../components/ui/Typography/Typography";
import useTranslation from "../hooks/useTranslation";
import SurveyDispatch from "../models/surveyDispatch";
import { GetSurveyDispatchResponse } from "../types/survey";
import "./SurveyLaunchPage.scss";

export const SurveyLaunchPage = () => {
  const surveyService = new SurveyService();
  const [surveyDispatch, setSurveyDispatch] = useState<SurveyDispatch>();
  const { t } = useTranslation("surveyLaunchPage");
  const dispatchIsActive = surveyDispatch && surveyDispatch.isActive();

  const {
    data: surveyDispatchData,
    isLoading: isLoadingSurveyDispatch,
    error: surveyDispatchError,
    refetch: handleSurveyDispatchRefetch,
  } = useQuery<GetSurveyDispatchResponse, AxiosError>(["surveyDispatch"], () =>
    surveyService.getSurveyDispatch()
  );

  const launchSurveyMutation = useMutation({
    mutationFn: () => surveyService.launchSurveyDispatch(),
  });

  const errorLoadingSurveyDispatch = () => {
    if (surveyDispatchError && surveyDispatchError.response?.status !== 404) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (
      launchSurveyMutation.data &&
      (!surveyDispatch || !surveyDispatch?.isActive())
    ) {
      setSurveyDispatch(new SurveyDispatch(launchSurveyMutation.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launchSurveyMutation.data]);

  useEffect(() => {
    if (surveyDispatchData && !surveyDispatch) {
      const surveyModel = new SurveyDispatch(surveyDispatchData);

      setSurveyDispatch(surveyModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDispatchData]);

  useEffect(() => {
    if (surveyDispatchData && surveyDispatch) {
      const updatedSurvey = { ...surveyDispatch };
      updatedSurvey.recipients = surveyDispatchData.recipients;
      setSurveyDispatch(updatedSurvey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDispatchData?.recipients]);

  const handleLaunchSurvey = () => {
    launchSurveyMutation.mutate();
  };

  const handleClose = () => {
    handleSurveyDispatchRefetch();
  };

  return (
    <div className="SurveyLaunchPage">
      <div className="SurveyLaunchPage__survey">
        <Grid container spacing={1} marginBottom={3}>
          <Grid item md={1} />
          <Grid item md={10}>
            <Typography
              desktop="h1"
              mobile="body1"
              tagVariant="h1"
              className="SurveyLaunchPage__survey__heading"
            >
              {t("title")}
            </Typography>

            {(!surveyDispatch || !surveyDispatch?.isActive()) && (
              <SurveyNoticeCard />
            )}

            {isLoadingSurveyDispatch &&
              !launchSurveyMutation.isLoading &&
              !errorLoadingSurveyDispatch() && (
                <div className="SurveyLaunchPage__survey__loading">
                  <LoadingIndicator />
                </div>
              )}
            {!surveyDispatch &&
              !isLoadingSurveyDispatch &&
              !errorLoadingSurveyDispatch() && (
                <div className="SurveyLaunchPage__survey__action">
                  <Button
                    variant="contained"
                    color="info"
                    onClick={handleLaunchSurvey}
                    loading={launchSurveyMutation.isLoading}
                    disabled={launchSurveyMutation.isLoading}
                  >
                    {t("startSurveySetup")}
                  </Button>
                  {!!launchSurveyMutation.error && (
                    <Typography desktop="h3" color="error">
                      {t("surveyStartError")}
                    </Typography>
                  )}
                </div>
              )}
            {errorLoadingSurveyDispatch() && (
              <Typography
                desktop="h3"
                color="error"
                className="SurveyLaunchPage__survey__action"
              >
                {t("surveyDispatchError")}
              </Typography>
            )}
          </Grid>
          <Grid item md={1} />
        </Grid>
        {dispatchIsActive && (
          <SurveyStatus
            survey={surveyDispatch}
            isLoading={false}
            error={false}
            noSurvey={false}
            onSurveyUpdate={handleSurveyDispatchRefetch}
          />
        )}
        {surveyDispatch && !dispatchIsActive && (
          <>
            <SurveyLaunchSteps
              onClose={handleClose}
              surveyDispatch={surveyDispatch}
              onSurveyUpdate={handleSurveyDispatchRefetch}
            />
          </>
        )}
      </div>
    </div>
  );
};
