import { AxiosResponse } from "axios";
import {
  Context,
  FC,
  ReactNode,
  createContext,
  useCallback,
  useState,
} from "react";
import { useAuth } from "../hooks/useAuth";
import User from "../models/user";
import { UserResponse } from "../types/user";
import {
  ACCESS_TOKEN_KEY,
  CLIENT_ID,
  USER,
  getApiClient,
} from "../utils/api.utils";

export interface DemoAuthInfo {
  user: User | undefined;
}

export interface DemoAuthResponse {
  access: string | undefined;
  refresh: string | undefined;
  user: UserResponse | undefined;
}

export interface IDemoAuthContext {
  loadingDemoAuth: boolean;
  onDemoLogin: (
    email: string,
    password: string
  ) => Promise<AxiosResponse<DemoAuthResponse>>;
}

export const DemoAuthContext: Context<IDemoAuthContext> =
  createContext<IDemoAuthContext>({
    onDemoLogin: () => new Promise(() => {}),
    loadingDemoAuth: false,
  });

export const DemoAuthProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { setAuthInfo } = useAuth();
  const [loadingDemoAuth, setLoadingDemoAuth] = useState(false);

  const handleLogin = useCallback(
    async (
      email: string,
      password: string
    ): Promise<AxiosResponse<DemoAuthResponse>> => {
      setLoadingDemoAuth(true);
      const request = getApiClient(false).post<DemoAuthResponse>(
        "/auth/demo/",
        new URLSearchParams({
          email,
          password,
          grant_type: "password",
          client_id: CLIENT_ID,
        }),
        {
          withCredentials: true,
        }
      );

      return new Promise<AxiosResponse<DemoAuthResponse>>((resolve, reject) => {
        request
          .then((res) => {
            if (!res.data.user) {
              throw new Error("No user data in response");
            }

            const user = new User(res.data.user);

            setAuthInfo({
              user: user,
            });
            localStorage.setItem(ACCESS_TOKEN_KEY, res.data.access || "");
            localStorage.setItem(USER, JSON.stringify(user));

            resolve(res);
          })
          .catch((err) => {
            console.error("Error logging in (in demo authentication)", err);
            reject(err);
          })
          .finally(() => {
            setLoadingDemoAuth(false);
          });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const value = {
    setAuthInfo,
    loadingDemoAuth,
    onDemoLogin: handleLogin,
  };

  return process.env.REACT_APP_FEATURE_DEMO_AUTHENTICATION === "true" ? (
    <DemoAuthContext.Provider value={value}>
      {children}
    </DemoAuthContext.Provider>
  ) : (
    <>{children}</>
  );
};
