import { Grid, Step, StepButton, StepContent, Stepper } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { SurveyService } from "../../api/SurveyService";
import useTranslation from "../../hooks/useTranslation";
import SurveyDispatch from "../../models/surveyDispatch";
import {
  GetMappedOptions,
  SurveyEmailLanguage,
  SurveyEmailResponse,
} from "../../types/survey";
import { ScreeningQuestionOverview } from "../ScreeningQuestionOverview/ScreeningQuestionOverview";
import { SurveyIntroductionEmail } from "../SurveyIntroductionEmail/SurveyIntroductionEmail";
import { SurveyLaunchJobRoles } from "../SurveyLaunchJobRoles/SurveyLaunchJobRoles";
import { SurveyPreviewCard } from "../SurveyPreviewCard/SurveyPreviewCard";
import { SurveyRecipients } from "../SurveyRecipients/SurveyRecipients";
import { SurveySettings } from "../SurveySettings/SurveySettings";
import { SurveySummary } from "../SurveySummary/SurveySummary";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";
import "./SurveyLaunchSteps.scss";

export interface SurveyLaunchStepsProps {
  onClose: () => void;
  surveyDispatch: SurveyDispatch;
  onSurveyUpdate: () => void;
}
export const SurveyLaunchSteps = ({
  surveyDispatch,
  onClose,
  onSurveyUpdate,
}: SurveyLaunchStepsProps) => {
  const { t } = useTranslation("surveyLaunchPage");
  const [step, setStep] = useState(0);
  const surveyService = new SurveyService();
  const {
    data: jobRolesData,
    isLoading: jobRolesIsLoading,
    error: jobRolesError,
    refetch: jobRolesRefetch,
  } = useQuery<GetMappedOptions, AxiosError>(
    ["mappedOptions"],
    () => surveyService.getOptionMappings(),
    { retry: false }
  );

  // get all available survey email languages from api
  const {
    data: availableSurveyEmailLanguages,
    isLoading: availableSurveyEmailLanguagesIsLoading,
    error: availableSurveyEmailLanguagesError,
  } = useQuery<SurveyEmailLanguage[], AxiosError>(
    ["availableSurveyEmailLanguages"],
    () => surveyService.getSupportedSurveyEmailLanguages()
  );

  const {
    data: existingSurveyEmail,
    isLoading: surveyEmailIsLoading,
    error: surveyEmailError,
    refetch: refetchSurveyEmail,
  } = useQuery<SurveyEmailResponse, AxiosError>(["surveyEmail"], () =>
    surveyService.getSurveyEmail(surveyDispatch.id)
  );

  const loadingEmailData =
    surveyEmailIsLoading || availableSurveyEmailLanguagesIsLoading;

  const handleNext = () => {
    if (step === steps.length - 1) {
      onClose();
    }
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };
  const handleStep = (step: number) => () => {
    setStep(step);
  };
  const steps = [
    t("steps.firstPreview"),
    t("steps.customiseJobRoles"),
    t("steps.customQuestion"),
    t("steps.preview"),
    t("steps.previewEmail"),
    t("steps.selectRecipients"),
    t("steps.dueDate"),
    t("steps.summary"),
  ];

  const renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <SurveyPreviewCard
            surveyId={surveyDispatch?.surveyId}
            isLoading={!surveyDispatch}
            firstPreview
            isSetup
          />
        );
      case 1:
        return (
          <SurveyLaunchJobRoles
            optionMappingData={jobRolesData?.mapped_options}
            isLoading={jobRolesIsLoading}
            error={!!jobRolesError}
            refetch={jobRolesRefetch}
          />
        );
      case 2:
        return <ScreeningQuestionOverview />;
      case 3:
        return (
          <SurveyPreviewCard
            surveyId={surveyDispatch?.surveyId}
            isLoading={!surveyDispatch}
            isSetup
          />
        );
      case 4:
        return (
          <SurveyIntroductionEmail
            availableSurveyEmailLanguages={availableSurveyEmailLanguages}
            errorLoadingData={
              !!availableSurveyEmailLanguagesError || !!surveyEmailError
            }
            existingSurveyEmail={existingSurveyEmail}
            loadingData={loadingEmailData}
            surveyDispatchId={surveyDispatch.id}
            refetchSurveyEmail={refetchSurveyEmail}
          />
        );
      case 5:
        return (
          <SurveyRecipients
            launchDate={surveyDispatch.launchDate}
            recipients={surveyDispatch.recipients}
            onSurveyUpdate={onSurveyUpdate}
          />
        );
      case 6:
        return surveyDispatch && <SurveySettings dispatch={surveyDispatch} />;
      case 7:
        return <SurveySummary />;
      default:
        return <></>;
    }
  };
  return (
    <Grid container spacing={3} marginBottom={3}>
      <Grid item sm={0} md={1} />
      <Grid item sm={12} md={10}>
        <Card className="SurveyLaunchSteps">
          <Stepper nonLinear activeStep={step} orientation="vertical">
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepButton
                    onClick={handleStep(index)}
                    disableRipple
                    sx={{ fontSize: "1.4rem" }}
                  >
                    {label}
                  </StepButton>
                  <StepContent
                    className="SurveyLaunchSteps__panel"
                    TransitionProps={{ unmountOnExit: false }}
                  >
                    {renderSteps()}
                    <div className="SurveyLaunchSteps__actions">
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleBack}
                        disabled={step === 0}
                        small
                      >
                        {t("actions.back")}
                      </Button>
                      <Button
                        variant="contained"
                        color="info"
                        onClick={handleNext}
                        small
                      >
                        {step === steps.length - 1
                          ? t("actions.finish")
                          : t("actions.next")}
                      </Button>
                    </div>
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>
        </Card>
      </Grid>
      <Grid item sm={12} md={1} />
    </Grid>
  );
};
