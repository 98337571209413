import { CardActionArea, Grid, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";
import { RecommendationIllustration } from "../../styles/illustrations/RecommendationIllustration";
import { GetSurveyRecommendationResponse } from "../../types/workplaceCulture";
import { ProtectedRoutes } from "../../utils/routes.utils";
import { NoDataState } from "../NoDataState/NoDataState";
import { DashboardWidget } from "../ui/DashboardWidget/DashboardWidget";
import { Typography } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import { mockRecommendationWidgetData } from "./RecommendationWidget.data";
import "./RecommendationWidget.scss";

interface PayEquityWidgetProps {
  recommendationsData?: GetSurveyRecommendationResponse;
  recommendationsDataLoading?: boolean;
  recommendationsDataError?: boolean;
  hasEnoughAnswers?: boolean;
  preview?: boolean;
}

export const RecommendationWidget = ({
  recommendationsData,
  recommendationsDataLoading: isLoading = true,
  recommendationsDataError: error = false,
  hasEnoughAnswers = false,
  preview = false,
}: PayEquityWidgetProps) => {
  const { t: tDashboard } = useTranslation("dashboardPage");
  const { t: tWorkplaceCulture } = useTranslation("workplaceCulturePage");

  const showMockedData =
    process.env.REACT_APP_MOCK_RECOMMENDATIONS === "true" || preview;

  let recommendations = undefined;
  if (recommendationsData && !showMockedData) {
    recommendations = recommendationsData;
  } else if (showMockedData) {
    recommendations = mockRecommendationWidgetData;
  }

  const hasRecommendations =
    (recommendations && recommendations.low_scores?.length > 0) ||
    (recommendations && recommendations.high_scores?.length > 0) ||
    (recommendations &&
      recommendations.insights &&
      recommendations.insights?.length > 0);

  const renderRecommendation = (recommendation: string, showArrow = false) => (
    <Grid
      container
      className="RecommendationWidget__recommendation"
      gap={1}
      key={recommendation}
    >
      <Grid item xs={1}>
        <Icon
          type={IconTypes.Message}
          className="RecommendationWidget__recommendation__icon"
        />
      </Grid>
      <Grid item xs={showArrow ? 9 : 10}>
        <Typography tagVariant="p" desktop="body2" weight="regular">
          {tWorkplaceCulture(recommendation)}
        </Typography>
      </Grid>
      {showArrow && (
        <Grid item xs={1}>
          <Icon
            type={IconTypes.RedArrow}
            className="RecommendationWidget__recommendation__icon"
          />
        </Grid>
      )}
    </Grid>
  );

  const renderErrorState = () => {
    let title = tWorkplaceCulture("recommendations.error.title");
    let info = tWorkplaceCulture("recommendations.error.info");

    if (!hasRecommendations) {
      title = tWorkplaceCulture("recommendations.noRecommendations.title");
      info = tWorkplaceCulture("recommendations.noRecommendations.info");
    }

    if (!hasEnoughAnswers) {
      title = tWorkplaceCulture("recommendations.notEnoughAnswers.title");
      info = tWorkplaceCulture("recommendations.notEnoughAnswers.info");
    }

    return <NoDataState title={title} info={info} />;
  };

  return (
    <DashboardWidget
      title={tDashboard("recommendationsWidget.title")}
      icon={RecommendationIllustration}
      preview={preview}
    >
      {!isLoading &&
        (!hasEnoughAnswers || !hasRecommendations || error) &&
        !showMockedData &&
        renderErrorState()}
      {((!error && hasEnoughAnswers && hasRecommendations) ||
        showMockedData) && (
        <div className="RecommendationWidget">
          {recommendations?.low_scores &&
            recommendations?.low_scores.length > 0 && (
              <CardActionArea
                component={Link}
                to={ProtectedRoutes.WORKPLACE_CULTURE}
                disableRipple
              >
                {renderRecommendation(
                  "recommendations.lowScores.infoOneLiner",
                  true
                )}
              </CardActionArea>
            )}
          {recommendations?.high_scores &&
            recommendations.high_scores.length > 0 && (
              <CardActionArea
                component={Link}
                to={ProtectedRoutes.WORKPLACE_CULTURE}
                disableRipple
              >
                {renderRecommendation(
                  "recommendations.highScores.infoOneLiner",
                  true
                )}
              </CardActionArea>
            )}

          {recommendations?.insights &&
            Array.isArray(recommendations?.insights) &&
            recommendations.insights?.map((insight) =>
              renderRecommendation(insight)
            )}
        </div>
      )}
      {isLoading && !showMockedData && <Skeleton height={100} />}
    </DashboardWidget>
  );
};
