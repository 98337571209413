import { Skeleton } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { SurveyService } from "../../api/SurveyService";
import useTranslation from "../../hooks/useTranslation";
import { MappedOption, OptionMapping } from "../../types/survey";
import { Button } from "../ui/Button/Button";
import { Typography } from "../ui/Typography/Typography";
import {
  isDuplicateOptions,
  isOptionsEmpty,
  transformOptionMappings,
} from "./SurveyLaunchJobRoles.utils";
import { SurveyLaunchJobRolesItem } from "./SurveyLaunchJobRolesItem";
import "./SurveyLaunchJobRoles.scss";

interface SurveyLaunchJobRolesProps {
  optionMappingData?: OptionMapping[];
  isLoading?: boolean;
  error?: boolean;
  refetch?: () => void;
}

export const SurveyLaunchJobRoles = ({
  optionMappingData,
  isLoading,
  error,
  refetch,
}: SurveyLaunchJobRolesProps) => {
  const { t } = useTranslation("surveyLaunchPage");
  const [optionMappings, setOptionMappings] = useState<OptionMapping[]>([]);
  const surveyService = new SurveyService();
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");
  const canSaveJobRoles =
    !isOptionsEmpty(optionMappings) &&
    isOptionsEmpty(optionMappingData) &&
    !isDuplicateOptions(optionMappings);
  const duplicateOptions = isDuplicateOptions(optionMappings);
  useEffect(() => {
    if (optionMappingData) {
      setOptionMappings(JSON.parse(JSON.stringify(optionMappingData)));
    }
  }, [optionMappingData]);

  const addJobRolesMutation = useMutation({
    mutationFn: (jobRoles: MappedOption[]) =>
      surveyService.addMappedOptions(jobRoles),
  });

  const handleAddOption = (optionMappingIndex: number) => {
    const newOptions = [...optionMappings];
    newOptions[optionMappingIndex].options.push({
      text: "",
      label: "",
    });
    setOptionMappings(newOptions);
  };

  const handleDeleteOption = (
    OptionMappingIndex: number,
    optionIndex: number
  ) => {
    const newOptions = [...optionMappings];
    newOptions[OptionMappingIndex].options.splice(optionIndex, 1);
    setOptionMappings(newOptions);
  };

  const handleChangeOption = (
    value: string,
    index: number,
    optionMappingIndex: number
  ) => {
    const newOptions = [...optionMappings[optionMappingIndex].options];
    newOptions[index].text = value;
    const newOptionMappings = [...optionMappings];
    newOptionMappings[optionMappingIndex].options = newOptions;
    setOptionMappings(newOptionMappings);
  };

  const handleAddMappedOptions = async () => {
    setSubmitError("");
    const transformedOptions = transformOptionMappings(optionMappings);
    await addJobRolesMutation.mutateAsync(transformedOptions, {
      onSuccess: () => {
        setSubmitSuccess(t(`jobRoles.success`) || "");
        refetch && refetch();
      },
      onError: () => {
        setSubmitError(t(`jobRoles.errors.submit`) || "");
      },
    });
  };

  return (
    <div className="SurveyLaunchJobRoles">
      <Typography className="SurveyLaunchJobRoles__subtitle" desktop="body1">
        {t("jobRoles.subtitle")}
      </Typography>
      {isLoading && <Skeleton variant="rounded" width={"100%"} height={100} />}
      {error && (
        <Typography desktop="h3">{t("jobRoles.errors.noData")}</Typography>
      )}
      {!isLoading && !error && (
        <div>
          {optionMappings?.map((optionMapping, index) => (
            <SurveyLaunchJobRolesItem
              optionMapping={optionMapping}
              optionMappingIndex={index}
              handleAddOption={handleAddOption}
              handleDeleteOption={handleDeleteOption}
              handleChangeOption={handleChangeOption}
              key={index}
              disabled={!!submitSuccess || !isOptionsEmpty(optionMappingData)} // Disable until we have update endpoint
            />
          ))}
        </div>
      )}
      <div className="SurveyLaunchJobRoles__actions">
        {duplicateOptions && (
          <Typography desktop="caption" color="error">
            {t("jobRoles.errors.duplicate")}
          </Typography>
        )}
        <Button
          disabled={!canSaveJobRoles || !!submitSuccess}
          variant="contained"
          onClick={() => handleAddMappedOptions()}
        >
          {t("actions.save")}
        </Button>
        {isOptionsEmpty(optionMappings) && (
          <Typography desktop="caption">{t("actions.saveInfo")}</Typography>
        )}
        {canSaveJobRoles && !submitSuccess && (
          <Typography desktop="caption">{t("actions.warning")}</Typography>
        )}
        {submitError && (
          <Typography desktop="caption" color="error">
            {submitError}
          </Typography>
        )}
        {submitSuccess && (
          <Typography desktop="caption">{submitSuccess}</Typography>
        )}
      </div>
    </div>
  );
};
