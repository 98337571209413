import { GetSurveyScoringDemographicResponse } from "../../types/workplaceCulture";

export const scoringResponse: GetSurveyScoringDemographicResponse = {
  // overall, without filter
  id: undefined,
  name: undefined,
  scores: {
    total_score: 3.09,
    filters: [
      {
        label: "Role",
        id: "QUE.001",
        groups: [
          {
            label: "Team Manager",
            score: 2.24,
            id: "OPT.0001",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Manager",
            score: 3.18,
            id: "OPT.0002",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Specialist",
            score: 3.08,
            id: "OPT.0003",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Employee",
            score: 3.48,
            id: "OPT.0004",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Other role",
            score: 3.48,
            id: "OPT.0005",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "No Disclosure - Role",
            score: 2.48,
            id: "OPT.0005",
            is_scope: true,
            is_skip: true,
          },
        ],
      },
      {
        label: "Age",
        id: "QUE.002",
        groups: [
          {
            label: "18-25",
            score: 2.26,
            id: "OPT.0006",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "26-35",
            score: 3.48,
            id: "OPT.0007",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "36-45",
            score: 3.63,
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "46-55",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
          {
            label: ">55",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
        ],
      },
      {
        label: "Gender",
        id: "QUE.003",
        groups: [
          {
            label: "Female",
            score: 2.6,
            id: "OPT.0009",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Male",
            score: 3.52,
            id: "OPT.0010",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Non-binary",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Other gender",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
        ],
      },
      {
        label: "Transgender",
        id: "QUE.004",
        groups: [
          {
            label: "Transgender",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
        ],
      },
      {
        label: "LGBTQ+",
        id: "QUE.005",
        groups: [
          {
            label: "LGBTQ+",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
        ],
      },
      {
        label: "Disability",
        id: "QUE.006",
        groups: [
          {
            label: "Disability",
            score: 3.2,
            id: "OPT.0011",
            is_scope: false,
            is_skip: false,
          },
        ],
      },
      {
        label: "Ethnicity",
        id: "QUE.007",
        groups: [
          {
            label: "No foreign background",
            score: 3.62,
            id: "OPT.0012",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Immigrant",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "2nd generation immigrants",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Other foreign background",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
        ],
      },
    ],
  },
};

export const scoringResponseFew: GetSurveyScoringDemographicResponse = {
  // with filter
  id: "OPT.0001",
  name: "Team Manager",
  scores: {
    total_score: 3.09,
    filters: [
      {
        label: "Role",
        id: "QUE.001",
        groups: [
          {
            label: "Team Manager",
            score: 2.24,
            id: "OPT.0001",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "No Disclosure - Role",
            score: 4.24,
            id: "OPT.0099",
            is_scope: true,
            is_skip: true,
          },
          {
            label: "Manager",
            id: "OPT.0008",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Specialist",
            id: "OPT.0008",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Employee",
            id: "OPT.0008",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Other role",
            id: "OPT.0008",
            is_scope: true,
            is_skip: false,
          },
        ],
      },
      {
        label: "Age",
        id: "QUE.001",
        groups: [
          {
            label: "18-25",
            score: 2.26,
            id: "OPT.0002",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "26-35",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "36-45",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "46-55",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
          {
            label: ">55",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
        ],
      },
      {
        label: "Gender",
        id: "QUE.001",
        groups: [
          {
            label: "Female",
            score: 3.26,
            id: "OPT.0009",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Male",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Non-binary",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Other gender",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
        ],
      },
      {
        label: "Transgender",
        id: "QUE.001",
        groups: [
          {
            label: "Transgender",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
        ],
      },
      {
        label: "LGBTQ+",
        id: "QUE.001",
        groups: [
          {
            label: "LGBTQ+",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
        ],
      },
      {
        label: "Disability",
        id: "QUE.001",
        groups: [
          {
            label: "Disability",
            score: 2.24,
            id: "OPT.0004",
            is_scope: false,
            is_skip: false,
          },
        ],
      },
      {
        label: "Ethnicity",
        id: "QUE.001",
        groups: [
          {
            label: "No foreign background",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Immigrant",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "2nd generation immigrants",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Other foreign background",
            id: "OPT.0008",
            is_scope: false,
            is_skip: false,
          },
        ],
      },
    ],
  },
};

export const detailedScoringResponse: GetSurveyScoringDemographicResponse = {
  id: undefined,
  name: undefined,
  scores: {
    total_score: 3.99,
    filters: [
      {
        label: "Location",
        id: "CQUE.4085fa27-6681-4b8b-bb2f-381478747266",
        groups: [
          {
            label: "Mumbai",
            id: "COPT.5fbb8a0d-4957-470c-8050-160c475632b2",
            score: 3.99,
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Oslo (Bærum)",
            id: "COPT.403e1753-6057-4038-b6e9-5841179e1ac5",
            score: 4.02,
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Bergen",
            id: "COPT.4fc0b3e6-1dde-4686-a3b8-77e474572dbc",
            score: 4.11,
            is_scope: true,
            is_skip: false,
          },
          {
            label: "London",
            id: "COPT.7a90e2f1-8d84-41a3-ae63-aa3fd2774d47",
            score: 4.23,
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Kristiansand",
            id: "COPT.bc5236d8-3c58-4b24-b593-0e322f07b811",
            score: 4.12,
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Trondheim",
            id: "COPT.78a86aa8-e895-4389-a805-5ce65aaa624e",
            score: 4.3,
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Stavanger",
            id: "COPT.190a347a-5fe5-4f86-9a73-32bfd40b6ca1",
            score: 3.9,
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Houston",
            id: "COPT.70b2f19b-df4e-4d79-8865-ca159b2cb4ef",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Atlantic Place",
            id: "COPT.5b75ba72-1ae1-45dc-88ae-b8776a42f632",
            score: 4.66,
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Aberdeen",
            id: "COPT.bc2d963a-be7d-4dcd-9ad7-c44c53ab8885",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Tranby",
            id: "COPT.60288fcb-6927-4624-b7b5-5a3017d4769a",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Stord",
            id: "COPT.2fc0acde-44a5-402d-8122-e7b58a44be29",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Verdal",
            id: "COPT.915c30f7-2a4b-4e3a-90c7-caef8b448fcd",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Ulvila",
            id: "COPT.047f3ee8-9273-4dc4-9916-93cbcecb17e2",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Other",
            id: "COPT.4ddaa9c4-e037-46da-8454-765a14642335",
            is_scope: true,
            is_skip: false,
          },
        ],
      },
      {
        label: "Country",
        id: "CQUE.cf59baa8-b16c-4c37-9006-072b74ccb3dd",
        groups: [
          {
            label: "India",
            id: "COPT.d77516c3-fa76-4249-bc6d-787d1cbbd91b",
            score: 3.99,
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Norway",
            id: "COPT.7231a1b8-42bd-4c4c-9713-0af78ca4fc4b",
            score: 4.03,
            is_scope: true,
            is_skip: false,
          },
          {
            label: "UK",
            id: "COPT.25d5afdb-db28-4247-8892-2c3fdf544482",
            score: 4.22,
            is_scope: true,
            is_skip: false,
          },
          {
            label: "US",
            id: "COPT.f086bed1-084b-4c8b-996b-42cb1fd4b11d",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Canada",
            id: "COPT.9a66c644-ab5b-49f5-9ae6-67548183b3d5",
            is_scope: true,
            is_skip: false,
            score: 4.66,
          },
          {
            label: "Finland",
            id: "COPT.a1ef49e2-54b6-496b-b8fc-0d3435c08c13",
            is_scope: true,
            is_skip: false,
          },
          {
            label: "Other",
            id: "COPT.6bb8d9b1-3251-4f8c-bb9f-a9b0e6d9b6df",
            is_scope: true,
            is_skip: false,
          },
        ],
      },
      {
        label: "Fjar- eða staðarvinna",
        id: "QUE.001",
        groups: [
          {
            label: "Staðvinna",
            id: "OPT.0001",
            score: 3.99,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Fjar- og staðvinna",
            id: "OPT.0002",
            score: 3.99,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Fjarvinna",
            id: "OPT.0003",
            score: 4.3,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Annað",
            id: "OPT.0004",
            score: 3.95,
            is_scope: false,
            is_skip: false,
          },
        ],
      },
      {
        label: "Hlutverk",
        id: "QUE.002",
        groups: [
          {
            label: "Graduate / Entry level",
            id: "COPT.151eaaa1-1bd4-4a00-ab3b-ab8ddc6949e3",
            is_scope: false,
            is_skip: false,
            score: 4.24,
          },
          {
            label: "Management / Experienced Professional",
            id: "COPT.6b63c964-7742-4934-a45a-160dfe0b74a1",
            is_scope: false,
            is_skip: false,
            score: 3.92,
          },
          {
            label: "Senior Management / Senior Professional",
            id: "COPT.da47051f-7e8b-4de5-8c98-3844f3c38af8",
            is_scope: false,
            is_skip: false,
            score: 4.04,
          },
          {
            label: "Executive",
            id: "COPT.8a9b490b-10d4-47b8-af96-5c755fa71839",
            is_scope: false,
            is_skip: false,
            score: 4.13,
          },
        ],
      },
      {
        label: "Aldur",
        id: "QUE.008",
        groups: [
          {
            label: "18-25",
            id: "OPT.0036",
            score: 3.95,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "26-35",
            id: "OPT.0037",
            score: 3.99,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "36-45",
            id: "OPT.0038",
            score: 3.88,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "46-55",
            id: "OPT.0039",
            is_scope: false,
            is_skip: false,
            score: 4.18,
          },
          {
            label: "56-65",
            id: "OPT.0040",
            is_scope: false,
            is_skip: false,
            score: 4.17,
          },
          {
            label: ">65",
            id: "OPT.0041",
            is_scope: false,
            is_skip: false,
            score: 4.54,
          },
        ],
      },
      {
        label: "Kyn",
        id: "QUE.009",
        groups: [
          {
            label: "Konur",
            id: "OPT.0043",
            score: 4.03,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Karlar",
            id: "OPT.0044",
            score: 3.99,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Kvár",
            id: "OPT.0045",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Önnur kyn",
            id: "OPT.0046",
            is_scope: false,
            is_skip: false,
          },
        ],
      },
      {
        label: "Hinsegin",
        id: "QUE.011",
        groups: [
          {
            label: "Hinsegin fólk",
            id: "OPT.0051",
            score: 3.49,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Trans fólk",
            id: "OPT.0048",
            is_scope: false,
            is_skip: false,
          },
        ],
      },
      {
        label: "Uppruni",
        id: "QUE.012",
        groups: [
          {
            label: "Asian",
            id: "OPT.0054",
            score: 3.95,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Black",
            id: "OPT.0055",
            score: 3.9,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Hispanic or Latino",
            id: "OPT.0056",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Indigenous",
            id: "OPT.0057",
            score: 4.12,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "White",
            id: "OPT.0058",
            score: 4.22,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Mixed",
            id: "OPT.0059",
            score: 4.54,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Mixed - but white passing",
            id: "OPT.0060",
            score: 4.11,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Other",
            id: "OPT.0061",
            score: 3.69,
            is_scope: false,
            is_skip: false,
          },
        ],
      },
      {
        label: "Innflytjendastaða",
        id: "QUE.013",
        groups: [
          {
            label: "Innfædd",
            id: "OPT.0063",
            score: 4.05,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Innflytjendur",
            id: "OPT.0064",
            score: 3.46,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Annarrar kynslóðar innflytjendur",
            id: "OPT.0065",
            score: 4.01,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Flóttafólk",
            id: "OPT.0066",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Hælisleitandi einstaklingar",
            id: "OPT.0067",
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Einstaklingar með annan erlendan bakgrunn",
            id: "OPT.0068",
            score: 3.85,
            is_scope: false,
            is_skip: false,
          },
        ],
      },
      {
        label: "Móðurmál",
        id: "QUE.014",
        groups: [
          {
            label: "Native speakers",
            id: "OPT.0070",
            score: 4.18,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Non-native speakers",
            id: "OPT.0071",
            score: 3.9,
            is_scope: false,
            is_skip: false,
          },
        ],
      },
      {
        label: "Fatlað fólk",
        id: "QUE.015",
        groups: [
          {
            label: "Fatlað fólk",
            id: "OPT.0073",
            score: 4.31,
            is_scope: false,
            is_skip: false,
          },
        ],
      },
      {
        label: "Taugsegin",
        id: "QUE.016",
        groups: [
          {
            label: "Taugsegin einstaklingar",
            id: "OPT.0076",
            score: 4.21,
            is_scope: false,
            is_skip: false,
          },
          {
            label: "Taugatýpískir einstaklingar",
            id: "OPT.0077",
            score: 3.99,
            is_scope: false,
            is_skip: false,
          },
        ],
      },
    ],
  },
};

export const detailedFilteredScoringResponse: GetSurveyScoringDemographicResponse =
  {
    id: "COPT.7231a1b8-42bd-4c4c-9713-0af78ca4fc4b",
    name: "Norway",
    scores: {
      total_score: 4.03,
      filters: [
        {
          label: "Location",
          id: "CQUE.4085fa27-6681-4b8b-bb2f-381478747266",
          groups: [
            {
              label: "Mumbai",
              id: "COPT.5fbb8a0d-4957-470c-8050-160c475632b2",
              is_scope: true,
              is_skip: false,
            },
            {
              label: "Oslo (Bærum)",
              id: "COPT.403e1753-6057-4038-b6e9-5841179e1ac5",
              is_scope: true,
              is_skip: false,
              score: 4,
            },
            {
              label: "Bergen",
              id: "COPT.4fc0b3e6-1dde-4686-a3b8-77e474572dbc",
              is_scope: true,
              is_skip: false,
              score: 4.05,
            },
            {
              label: "London",
              id: "COPT.7a90e2f1-8d84-41a3-ae63-aa3fd2774d47",
              is_scope: true,
              is_skip: false,
            },
            {
              label: "Kristiansand",
              id: "COPT.bc5236d8-3c58-4b24-b593-0e322f07b811",
              is_scope: true,
              is_skip: false,
              score: 4.26,
            },
            {
              label: "Trondheim",
              id: "COPT.78a86aa8-e895-4389-a805-5ce65aaa624e",
              is_scope: true,
              is_skip: false,
              score: 4.3,
            },
            {
              label: "Stavanger",
              id: "COPT.190a347a-5fe5-4f86-9a73-32bfd40b6ca1",
              is_scope: true,
              is_skip: false,
              score: 3.9,
            },
            {
              label: "Houston",
              id: "COPT.70b2f19b-df4e-4d79-8865-ca159b2cb4ef",
              is_scope: true,
              is_skip: false,
            },
            {
              label: "Atlantic Place",
              id: "COPT.5b75ba72-1ae1-45dc-88ae-b8776a42f632",
              is_scope: true,
              is_skip: false,
            },
            {
              label: "Aberdeen",
              id: "COPT.bc2d963a-be7d-4dcd-9ad7-c44c53ab8885",
              is_scope: true,
              is_skip: false,
            },
            {
              label: "Tranby",
              id: "COPT.60288fcb-6927-4624-b7b5-5a3017d4769a",
              is_scope: true,
              is_skip: false,
            },
            {
              label: "Stord",
              id: "COPT.2fc0acde-44a5-402d-8122-e7b58a44be29",
              is_scope: true,
              is_skip: false,
            },
            {
              label: "Verdal",
              id: "COPT.915c30f7-2a4b-4e3a-90c7-caef8b448fcd",
              is_scope: true,
              is_skip: false,
            },
            {
              label: "Ulvila",
              id: "COPT.047f3ee8-9273-4dc4-9916-93cbcecb17e2",
              is_scope: true,
              is_skip: false,
            },
            {
              label: "Other",
              id: "COPT.4ddaa9c4-e037-46da-8454-765a14642335",
              is_scope: true,
              is_skip: false,
            },
          ],
        },
        {
          label: "Country",
          id: "CQUE.cf59baa8-b16c-4c37-9006-072b74ccb3dd",
          groups: [
            {
              label: "India",
              id: "COPT.d77516c3-fa76-4249-bc6d-787d1cbbd91b",
              is_scope: true,
              is_skip: false,
            },
            {
              label: "Norway",
              id: "COPT.7231a1b8-42bd-4c4c-9713-0af78ca4fc4b",
              is_scope: true,
              is_skip: false,
              score: 4.03,
            },
            {
              label: "UK",
              id: "COPT.25d5afdb-db28-4247-8892-2c3fdf544482",
              is_scope: true,
              is_skip: false,
            },
            {
              label: "US",
              id: "COPT.f086bed1-084b-4c8b-996b-42cb1fd4b11d",
              is_scope: true,
              is_skip: false,
            },
            {
              label: "Canada",
              id: "COPT.9a66c644-ab5b-49f5-9ae6-67548183b3d5",
              is_scope: true,
              is_skip: false,
            },
            {
              label: "Finland",
              id: "COPT.a1ef49e2-54b6-496b-b8fc-0d3435c08c13",
              is_scope: true,
              is_skip: false,
            },
            {
              label: "Other",
              id: "COPT.6bb8d9b1-3251-4f8c-bb9f-a9b0e6d9b6df",
              is_scope: true,
              is_skip: false,
            },
          ],
        },
        {
          label: "Fjar- eða staðarvinna",
          id: "QUE.001",
          groups: [
            {
              label: "Staðvinna",
              id: "OPT.0001",
              score: 4.1,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Fjar- og staðvinna",
              id: "OPT.0002",
              score: 3.99,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Fjarvinna",
              id: "OPT.0003",
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Annað",
              id: "OPT.0004",
              is_scope: false,
              is_skip: false,
            },
          ],
        },
        {
          label: "Hlutverk",
          id: "QUE.002",
          groups: [
            {
              label: "Graduate / Entry level",
              id: "COPT.151eaaa1-1bd4-4a00-ab3b-ab8ddc6949e3",
              score: 4.41,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Management / Experienced Professional",
              id: "COPT.6b63c964-7742-4934-a45a-160dfe0b74a1",
              score: 3.99,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Senior Management / Senior Professional",
              id: "COPT.da47051f-7e8b-4de5-8c98-3844f3c38af8",
              score: 4.01,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Executive",
              id: "COPT.8a9b490b-10d4-47b8-af96-5c755fa71839",
              is_scope: false,
              is_skip: false,
            },
          ],
        },
        {
          label: "Aldur",
          id: "QUE.008",
          groups: [
            {
              label: "18-25",
              id: "OPT.0036",
              score: 3.99,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "26-35",
              id: "OPT.0037",
              score: 3.99,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "36-45",
              id: "OPT.0038",
              score: 3.76,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "46-55",
              id: "OPT.0039",
              score: 4.18,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "56-65",
              id: "OPT.0040",
              score: 4.08,
              is_scope: false,
              is_skip: false,
            },
            {
              label: ">65",
              id: "OPT.0041",
              is_scope: false,
              is_skip: false,
            },
          ],
        },
        {
          label: "Kyn",
          id: "QUE.009",
          groups: [
            {
              label: "Konur",
              id: "OPT.0043",
              score: 4.16,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Karlar",
              id: "OPT.0044",
              score: 3.99,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Kvár",
              id: "OPT.0045",
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Önnur kyn",
              id: "OPT.0046",
              is_scope: false,
              is_skip: false,
            },
          ],
        },
        {
          label: "Hinsegin",
          id: "QUE.011",
          groups: [
            {
              label: "Hinsegin fólk",
              id: "OPT.0051",
              score: 3.99,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Trans fólk",
              id: "OPT.0048",
              is_scope: false,
              is_skip: false,
            },
          ],
        },
        {
          label: "Uppruni",
          id: "QUE.012",
          groups: [
            {
              label: "Asian",
              id: "OPT.0054",
              score: 3.4,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Black",
              id: "OPT.0055",
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Hispanic or Latino",
              id: "OPT.0056",
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Indigenous",
              id: "OPT.0057",
              is_scope: false,
              is_skip: false,
            },
            {
              label: "White",
              id: "OPT.0058",
              score: 4.18,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Mixed",
              id: "OPT.0059",
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Mixed - but white passing",
              id: "OPT.0060",
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Other",
              id: "OPT.0061",
              score: 3.72,
              is_scope: false,
              is_skip: false,
            },
          ],
        },
        {
          label: "Innflytjendastaða",
          id: "QUE.013",
          groups: [
            {
              label: "Innfædd",
              id: "OPT.0063",
              score: 4.2,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Innflytjendur",
              id: "OPT.0064",
              score: 3.33,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Annarrar kynslóðar innflytjendur",
              id: "OPT.0065",
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Flóttafólk",
              id: "OPT.0066",
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Hælisleitandi einstaklingar",
              id: "OPT.0067",
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Einstaklingar með annan erlendan bakgrunn",
              id: "OPT.0068",
              score: 3.85,
              is_scope: false,
              is_skip: false,
            },
          ],
        },
        {
          label: "Móðurmál",
          id: "QUE.014",
          groups: [
            {
              label: "Native speakers",
              id: "OPT.0070",
              score: 4.14,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Non-native speakers",
              id: "OPT.0071",
              score: 3.95,
              is_scope: false,
              is_skip: false,
            },
          ],
        },
        {
          label: "Fatlað fólk",
          id: "QUE.015",
          groups: [
            {
              label: "Fatlað fólk",
              id: "OPT.0073",
              score: 3.41,
              is_scope: false,
              is_skip: false,
            },
          ],
        },
        {
          label: "Taugsegin",
          id: "QUE.016",
          groups: [
            {
              label: "Taugsegin einstaklingar",
              id: "OPT.0076",
              score: 3.38,
              is_scope: false,
              is_skip: false,
            },
            {
              label: "Taugatýpískir einstaklingar",
              id: "OPT.0077",
              score: 4.05,
              is_scope: false,
              is_skip: false,
            },
          ],
        },
      ],
    },
  };
