import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SurveyService } from "../api/SurveyService";
import { Header } from "../components/Header/Header";
import { SurveyFlow } from "../components/SurveyFlow/SurveyFlow";
import { SurveyInvalid } from "../components/SurveyInvalid/SurveyInvalid";
import { LoadingScreen } from "../components/ui/LoadingScreen";
import { ProgressBar } from "../components/ui/ProgressBar/ProgressBar";
import useTranslation from "../hooks/useTranslation";
import Survey from "../models/survey";
import "./SurveyPreviewPage.scss";

export const SurveyPreviewPage = () => {
  const { t } = useTranslation("surveyPage");
  const [survey, setSurvey] = useState<Survey>();
  const [progress, setSurveyProgress] = useState(0);
  const surveyService = new SurveyService();
  const [surveyIdVerified, setSurveyIdVerified] = useState(false);
  const navigate = useNavigate();

  const { surveyId } = useParams();
  const { data: surveyData, isLoading } = useQuery(
    [`survey-preview`],
    () => surveyService.previewSurvey(surveyId || ""),
    {
      enabled: !!surveyId,
    }
  );

  useEffect(() => {
    if (surveyData && !surveyIdVerified) {
      setSurveyIdVerified(true);
      setSurvey(new Survey(surveyData));
    }
  }, [surveyData, surveyIdVerified]);

  const handleSubmitSurvey = async (): Promise<boolean> => {
    goBack();
    return new Promise(() => true);
  };

  const goBack = () => {
    if (!!(window.document.location.hash === "")) {
      navigate(-1);
    } else if (window.document.location.hash === "#showAction") {
      window.close();
    }
  };

  return (
    <div className="SurveyPreviewPage">
      <Header
        showInfo={true}
        showProgress={progress !== 0}
        progress={progress}
        showAction={true}
        onActionClick={goBack}
        infoMsg={t("surveyPreviewMsg")}
        stackItemsInMobile
      />
      {isLoading && (
        <LoadingScreen active={isLoading} text={t("loading.survey")} />
      )}
      {surveyIdVerified && survey && (
        <SurveyFlow
          survey={survey}
          className="SurveyPreviewPage__page"
          setSurveyProgress={setSurveyProgress}
          onSurveySubmit={handleSubmitSurvey}
          isPreview
        />
      )}
      {!surveyIdVerified && !isLoading && (
        <SurveyInvalid
          className="SurveyPreviewPage__page"
          title={t("invalid.title")}
          info={t("invalid.info")}
        />
      )}
      {progress !== 0 && (
        <div className="SurveyPreviewPage__progress">
          <ProgressBar progress={progress} vertical length="80vh" />
        </div>
      )}
    </div>
  );
};
