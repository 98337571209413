import { ScaleValue, ScaleValueGroup } from "../../../types/scale";

export const getScaleValues = <T extends ScaleValue>(
  values: T[],
  t: (str: string) => string,
  minScoreLabel?: string,
  maxScoreLabel?: string,
  small = false
) => {
  const groupedValuesByScore: ScaleValueGroup<T> = values.reduce(
    (group, value) => {
      // TODO: calculate precision based on percentages
      const roundedScore = value.score.toPrecision(2).toString();
      group[roundedScore] = group[roundedScore] || [];
      group[roundedScore].push(value);
      return group;
    },
    Object.create(null)
  );

  const scoresArray = Object.keys(groupedValuesByScore).sort(
    (a, b) => Number(a) - Number(b)
  );

  if (small && scoresArray.length) {
    const firstScore = scoresArray[0];
    groupedValuesByScore[firstScore].map(
      (scaleValue) =>
        (scaleValue.scoreLabel = minScoreLabel || t("scale.minScoreLabel"))
    );

    const lastScore = scoresArray[scoresArray.length - 1];
    groupedValuesByScore[lastScore].map(
      (scaleValue) =>
        (scaleValue.scoreLabel = maxScoreLabel || t("scale.maxScoreLabel"))
    );
  }

  return {
    groupedValuesByScore,
    scoresArray,
  };
};
