import { SelectChangeEvent, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Select } from "../ui/Select/Select";
import { StepContainer } from "./StepContainer";

const listOfCurrencies = [
  { value: "ISK", label: "Icelandic krona" },
  { value: "EUR", label: "Euro" },
  { value: "USD", label: "US Dollar" },
  { value: "GBP", label: "British pound" },
  { value: "DKK", label: "Danish krone" },
  { value: "NOK", label: "Norwegian krone" },
  { value: "SEK", label: "Swedish krona" },
];

interface PreferredCurrencyType {
  currency: string;
  setCurrency: (value: SetStateAction<string>) => void;
  setStep: Dispatch<SetStateAction<number>>;
  step: number;
}

export const PreferredCurrency = ({
  currency,
  setCurrency,
  setStep,
  step,
}: PreferredCurrencyType) => {
  const handleCurrencyChange = (event: SelectChangeEvent<unknown>) => {
    setCurrency(event.target.value as string);
  };
  return (
    <StepContainer
      step={step}
      submitFunction={() => setStep(step + 1)}
      setStep={setStep}
    >
      <Typography variant="h1" sx={{ pb: 3 }}>
        Currency
      </Typography>
      <Typography
        variant="body1"
        sx={{ pb: 3 }}
        style={{ display: "inline-block", fontSize: "1rem" }}
      >
        In terms of salary and other data, what is your preffered currency?{" "}
      </Typography>
      <Select
        labelId="currency"
        id="currency"
        value={currency}
        onChange={handleCurrencyChange}
        label="Preferred currency"
        className="SignUp__form__dropdown"
        items={listOfCurrencies}
      />
    </StepContainer>
  );
};
