import { TouchpointResponse } from "../types/training";

class TrainingTouchpoint {
  description: string;
  duration: string;
  order: number;
  previewLink: string;
  referenceId: string;
  thumbnail: string;
  title: string;

  constructor(parameters: TouchpointResponse) {
    this.referenceId = parameters.reference_id;
    this.description = parameters.description;
    this.duration = parameters.duration;
    this.order = parameters.order;
    this.referenceId = parameters.reference_id;
    this.thumbnail = parameters.thumbnail;
    this.title = parameters.title;
    this.previewLink = parameters.preview_link;
  }
}

export default TrainingTouchpoint;
