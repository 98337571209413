import { Route, Routes } from "react-router-dom";
import { Logout } from "./components/Logout";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { useAuth } from "./hooks/useAuth";
import { DashboardPage } from "./pages/DashboardPage";
import { DemoLoginPage } from "./pages/DemoLoginPage";
import { DiversityRatioPage } from "./pages/DiversityRatioPage";
import { ErrorPage } from "./pages/ErrorPage";
import { LoginPage } from "./pages/LoginPage";
import { PayEquityPage } from "./pages/PayEquityPage";
import { PipelinePage } from "./pages/PipelinePage";
import { SettingsPage } from "./pages/SettingsPage";
import { SignUpPage } from "./pages/SignUpPage";
import { SurveyLaunchPage } from "./pages/SurveyLaunchPage";
import { SurveyPage } from "./pages/SurveyPage";
import { SurveyPreviewPage } from "./pages/SurveyPreviewPage";
import { TrainingPage } from "./pages/TrainingPage";
import { WorkplaceCultureChapterPage } from "./pages/WorkplaceCultureChapterPage";
import { WorkplaceCulturePage } from "./pages/WorkplaceCulturePage";
import { SubscriptionActions } from "./types/user";
import {
  ProtectedRoutes,
  PublicRoutes,
  SurveyRoutes,
} from "./utils/routes.utils";

const Frame = () => {
  const { authInfo } = useAuth();

  // Change default to false after all users have logged in after this change as it will return false if users have not logged in again after this change
  const hasAccessToIntegrations = authInfo.user
    ? authInfo.user.hasAccess(SubscriptionActions.HR_INTEGRATION)
    : true;
  const hasAccessToLaunchSurvey = authInfo.user
    ? authInfo.user.hasAccess(SubscriptionActions.LAUNCH_SURVEY)
    : true;

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <ProtectedRoute>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        <Route path={PublicRoutes.SIGNUP} element={<SignUpPage />} />
        <Route path={PublicRoutes.LOGIN} element={<LoginPage />} />
        {process.env.REACT_APP_FEATURE_DEMO_AUTHENTICATION === "true" && (
          <Route path={PublicRoutes.DEMO_LOGIN} element={<DemoLoginPage />} />
        )}
        <Route path={PublicRoutes.LOGOUT} element={<Logout />} />
        <Route
          path={ProtectedRoutes.DASHBOARD}
          element={
            <ProtectedRoute>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        {hasAccessToLaunchSurvey && (
          <>
            <Route
              path={ProtectedRoutes.LAUNCH}
              element={
                <ProtectedRoute>
                  <SurveyLaunchPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ProtectedRoutes.WORKPLACE_CULTURE}
              element={
                <ProtectedRoute>
                  <WorkplaceCulturePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ProtectedRoutes.WORKPLACE_CULTURE + "/:chapterId"}
              element={
                <ProtectedRoute>
                  <WorkplaceCultureChapterPage />
                </ProtectedRoute>
              }
            />
          </>
        )}
        {hasAccessToIntegrations && (
          <>
            <Route
              path={ProtectedRoutes.DIVERSITY_RATIO}
              element={
                <ProtectedRoute>
                  <DiversityRatioPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ProtectedRoutes.PAY_EQUITY}
              element={
                <ProtectedRoute>
                  <PayEquityPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ProtectedRoutes.PIPELINE}
              element={
                <ProtectedRoute>
                  <PipelinePage />
                </ProtectedRoute>
              }
            />
          </>
        )}

        <Route
          path={ProtectedRoutes.SETTINGS}
          element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={ProtectedRoutes.TRAINING}
          element={
            <ProtectedRoute>
              <TrainingPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${SurveyRoutes.PREVIEW_SURVEY}/:surveyId`}
          element={<SurveyPreviewPage />}
        />
        <Route path={SurveyRoutes.START_SURVEY} element={<SurveyPage />} />
        <Route path="*" element={<ErrorPage errorCode={404} />} />
      </Routes>
    </>
  );
};

export default Frame;
