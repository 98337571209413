import { Box, SelectChangeEvent } from "@mui/material";
import { useLanguages } from "../../hooks/useLanguages";
import useTranslation from "../../hooks/useTranslation";
import {
  AVAILABLE_SURVEY_LANGUAGES,
  AvailableLanguages,
} from "../../types/locale";
import i18n from "../../utils/i18n";
import { Select } from "../ui/Select/Select";

export interface SurveyLanguagePickerProps {
  className?: string;
}

export const SurveyLanguagePicker = ({
  className,
}: SurveyLanguagePickerProps) => {
  const { t } = useTranslation();
  const { changeLanguage } = useLanguages();

  const availableSurveyLanguages = AVAILABLE_SURVEY_LANGUAGES.map((lang) => ({
    value: lang,
    label: t(`navigation.languages.${lang}`),
  }));

  const handleLanguageChange = (e: SelectChangeEvent<unknown>) => {
    const lang = e.target.value as AvailableLanguages;
    changeLanguage(lang);
    // We have to reload the window to update the translations
    window.location.reload();
  };

  return (
    <Box>
      <Select
        labelId="language-selector"
        id="language-selector"
        value={i18n.language}
        onChange={handleLanguageChange}
        className={className}
        items={availableSurveyLanguages}
      />
    </Box>
  );
};
