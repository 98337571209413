import { AxiosInstance } from "axios";
import {
  AddressBookResponse,
  CustomQuestionAddBody,
  CustomQuestionUpdateBody,
  GetSurveyDispatchResponse,
  GetSurveyResponse,
  MappedOption,
  PreviewSurveyResponse,
  SubmitSurveyBody,
  SurveyDispatchReminder,
  SurveyEmailBody,
  SurveyEmailLanguage,
  SurveyEmailResponse,
} from "../types/survey";
import { getApiClient } from "../utils/api.utils";

// TODO: get survey id from api
const SURVEY_ID = "SUR.001";

export class SurveyService {
  client: AxiosInstance;

  constructor(useAuth = true) {
    this.client = getApiClient(useAuth);
  }

  getSurveyDispatch = async () => {
    const response = await this.client.get<GetSurveyDispatchResponse>(
      `/surveys/${SURVEY_ID}/`
    );

    return response.data;
  };

  launchSurveyDispatch = async () => {
    const response = await this.client.post<GetSurveyDispatchResponse>(
      `/surveys/${SURVEY_ID}/dispatch/`
    );

    return response.data;
  };

  launchSurveyInvite = async (recipients: string[]) => {
    const response = await this.client.post(`/surveys/${SURVEY_ID}/invite/`, {
      recipients: recipients,
    });

    return response.data;
  };

  getSurvey = async (dispatchToken: string) => {
    const response = await this.client.get<GetSurveyResponse>(
      `/surveys/${dispatchToken}/start/`
    );

    return response.data;
  };

  previewSurvey = async (surveyId: string) => {
    const response = await this.client.get<PreviewSurveyResponse>(
      `/surveys/${surveyId}/preview/`
    );

    return response.data;
  };

  submitSurvey = async (dispatchToken: string, body: SubmitSurveyBody) => {
    if (!body.answers) {
      throw Error("No answers in submitSurvey body");
    }

    const response = await this.client.post(
      `/surveys/${dispatchToken}/submit/`,
      body
    );

    return response.data;
  };

  rescheduleSurvey = async (
    dispatchId: string,
    launchDate: Date,
    reminders: SurveyDispatchReminder[],
    dueDate: Date
  ) => {
    const response = await this.client.post(`/surveys/dispatch/reschedule/`, {
      dispatch_id: dispatchId,
      launch_date: launchDate,
      reminders: reminders,
      due_date: dueDate,
    });

    return response.data;
  };

  getAddressBook = async () => {
    const response = await this.client.get<AddressBookResponse>(
      "/surveys/address-book/"
    );

    return response.data;
  };

  getCustomQuestions = async () => {
    const response = await this.client.get(`/surveys/custom-questions/`);

    return response.data;
  };

  addCustomQuestion = async (body: CustomQuestionAddBody) => {
    const response = await this.client.post(
      `/surveys/custom-questions/add/`,
      body
    );

    return response.data;
  };

  updateCustomQuestion = async (
    questionId: string,
    body: CustomQuestionUpdateBody
  ) => {
    const response = await this.client.post(
      `/surveys/custom-questions/${questionId}/update/`,
      body
    );

    return response.data;
  };

  getOptionMappings = async () => {
    const response = await this.client.get(`/surveys/mapped-options/`);

    return response.data;
  };

  addMappedOptions = async (mappedOptions: MappedOption[]) => {
    const response = await this.client.post(`/surveys/mapped-options/add/`, {
      mapped_options: mappedOptions,
    });

    return response.data;
  };

  getSupportedSurveyEmailLanguages = async () => {
    const response = await this.client.get<SurveyEmailLanguage[]>(
      `/surveys/survey-email/language/`
    );

    return response.data;
  };

  getSurveyEmail = async (dispatchId: string) => {
    const response = await this.client.get<SurveyEmailResponse>(
      `surveys/dispatch/${dispatchId}/survey-email/`
    );

    return response.data;
  };

  updateSurveyEmail = async (
    dispatchId: string,
    surveyEmailBody: SurveyEmailBody
  ) => {
    const response = await this.client.post(
      `surveys/dispatch/${dispatchId}/survey-email/`,
      surveyEmailBody
    );

    return response.data;
  };
}
