import { OutlinedTextFieldProps, TextField } from "@mui/material";
import classNames from "classnames";
import { forwardRef } from "react";
import "./Input.scss";

export interface IInputProps extends Partial<OutlinedTextFieldProps> {
  className?: string;
  helperText?: string;
  htmlFor?: string;
  variant?: "outlined";
}

export const Input = forwardRef<HTMLInputElement, IInputProps>(
  ({ className, ...props }, ref) => {
    const classes = classNames("Input", className);

    return (
      <TextField
        className={classes}
        InputLabelProps={{ className: "Input__label" }}
        {...props}
        variant="outlined"
        inputRef={ref}
      />
    );
  }
);
