import classnames from "classnames";
import { ChangeEvent } from "react";
import "./Input.scss";

export interface IMultiSelectInputProps {
  className?: string;
  checked: boolean;
  label: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
}

export const MultiSelectInput = ({
  checked,
  label,
  className,
  value,
  onChange,
}: IMultiSelectInputProps) => {
  const inputClasses = classnames("InputOption", className, {
    "InputOption--selected": checked,
  });

  const checkboxClasses = classnames(
    "InputOption__label__checkbox",
    className,
    {
      "InputOption__label__checkbox--selected": checked,
    }
  );

  return (
    <div className={inputClasses}>
      <input
        className="InputOption__input"
        type="checkbox"
        id={value}
        checked={checked}
        value={value}
        onChange={(event) => onChange(event, value)}
      />
      <div className="InputOption__label">
        <label className="InputOption__label__text" htmlFor={value}>
          {label}
        </label>
        <span className={checkboxClasses}></span>
      </div>
    </div>
  );
};
